<template>
    <div class="page">
        <div class="media-kit-page">
            <h1>Media Kit</h1>
            <div class="main-content-wrapper">
                <div class="profiles-wrapper">
                    <div class="section-meta">
                        <h2 class="title">Profiles</h2>
                    </div>
                    <div class="section-content">
                        <agile :dots="false" ref="carousel" :navButtons="false" @after-change="showCurrentSlide($event)">
                            <div v-for="(item, index) in slidesMembers" :key="index">
                                <div class="slide">
                                    <div class="professor-photo">
                                        <img :src="getImgUrl(item.photo)"/>

                                        <div class="professor-photo-links">
                                            Download photos in 2 versions: <a target="_blank" :href="
                          require('../assets/img/mediakit/' +
                            item.photoBw +
                            '.jpeg')
                        " download class="professor-photo-download">Black & white </a> or <a target="_blank" :href="
                          require('../assets/img/mediakit/' +
                            item.photoColour +
                            '.jpeg')
                        " download class="professor-photo-download">Coloured </a>
                                        </div>
                                    </div>
                                    <h3>{{ item.title }}</h3>
                                    <p>
                                        {{ item.body }} </p>
                                </div>
                            </div>
                        </agile>
                        <div class="arrow-nav-buttons">
                            <div class="nav-arrow-button" @click="$refs.carousel.goToPrev()">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="24" cy="24" r="22.5" stroke="#2C6BA4" stroke-width="3"/>
                                    <path d="M27 30L21 24L27 18" stroke="#2C6BA4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div class="show-slides-number">
                                {{ slideNumber }} / {{ slidesMembers.length }}
                            </div>
                            <div class="nav-arrow-button go-next-button" @click="$refs.carousel.goToNext()">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="24" cy="24" r="22.5" stroke="#2C6BA4" stroke-width="3"/>
                                    <path d="M21 30L27 24L21 18" stroke="#2C6BA4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="logos-wrapper type-b">
                    <div class="section-meta">
                        <h2 class="title">Logos</h2>
                    </div>
                    <div class="section-content">
                        <div class="logo-container-type-b">
                            <div>
                                <div class="logo-img">
                                    <img src="../assets/img/mediakit/phoxbio-logo.png" alt="phobxbio"/>
                                </div>
                                <div>
                                    <div>
                                        <div class="logo-title"><strong>pHOXBIO</strong>:</div>
                                    </div>
                                    <div class="logo-type-categ-title">
                                        <div class="categ-title">PNG</div>
                                        <div class="categ-title-links">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXBIO/png/phoxbio_large_grey.png')
                          " download>phoxbio-lg-grey.png </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXBIO/png/phoxbio_large_white.png')
                          " download>phoxbio-lg-white.png </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="logo-type-categ-title">
                                        <div class="categ-title">JPG</div>
                                        <div class="categ-title-links">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXBIO/jpg/phoxbio_large_BW.jpg')
                          " download>phoxbio-lg-BW.jpg </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXBIO/jpg/phoxbio_large.jpg')
                          " download>phoxbio-lg.jpg </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="logo-type-categ-title">
                                        <div class="categ-title">Adobe Illustrator file</div>
                                        <div class="categ-title-links">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXBIO/ai/phoxbio_large_BW.ai')
                          " download>phoxbio-lg-BW.ai </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXBIO/ai/phoxbio_large_grey.ai')
                          " download>phoxbio-lg-grey.ai </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXBIO/ai/phoxbio_large_white.ai')
                          " download>phoxbio-lg-white.ai </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="logo-container-type-b">
                            <div>
                                <div class="logo-img">
                                    <img src="../assets/img/mediakit/ph-logo.png" alt="phoxwell"/>
                                </div>
                                <div>
                                    <div>
                                        <div class="logo-title"><strong>pHOXWELL</strong>:</div>
                                    </div>
                                    <div class="logo-type-categ-title">
                                        <div class="categ-title">PNG</div>
                                        <div class="categ-title-links">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXWELL/png/phoxwell_large_grey.png')
                          " download>phoxwell-lg-grey.png </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXWELL/png/phoxwell_large.png')
                          " download>phoxwell-lg.png </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="logo-type-categ-title">
                                        <div class="categ-title">JPG</div>
                                        <div class="categ-title-links">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXWELL/jpg/phoxwell_large_BW.jpg')
                          " download>phoxwell-lg-BW.jpg </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXWELL/jpg/phoxwell_large.jpg')
                          " download>phoxwell-lg.jpg </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="logo-type-categ-title">
                                        <div class="categ-title">Adobe Illustrator file</div>
                                        <div class="categ-title-links">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXWELL/ai/phoxwell_large_BW.ai')
                          " download>phoxwell-lg-BW.ai </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXWELL/ai/phoxwell_large_grey.ai')
                          " download>phoxwell-lg-grey.ai </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/pHOXWELL/ai/phoxwell_large_grey.ai')
                          " download>phoxwell-lg-grey.ai </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="logo-container-type-b">
                            <div>
                                <div class="logo-img">
                                    <img src="../assets/img/mediakit/raphael-logo.png" alt="Raphael labs"/>
                                </div>
                                <div>
                                    <div>
                                        <div class="logo-title"><strong>Raphael Labs</strong>:</div>
                                    </div>
                                    <div class="logo-type-categ-title">
                                        <div class="categ-title">PNG</div>
                                        <div class="categ-title-links">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/raphael-labs/png/Raphael_Labs_logo_large_red_blue.png')
                          " download>RL-large-red-blue.png </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/raphael-labs/png/Raphael_Labs_logo_small.png')
                          " download>RL-small.png </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="logo-type-categ-title">
                                        <div class="categ-title">JPG</div>
                                        <div class="categ-title-links">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/raphael-labs/jpg/Raphael_Labs_logo_large_BW.jpg')
                          " download>RL-large-BW.jpg </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/raphael-labs/jpg/Raphael_Labs_logo_large_red_blue.jpg')
                          " download>RL-large-red-blue.jpg </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/raphael-labs/jpg/Raphael_Labs_logo_large.jpg')
                          " download>RL-large.jpg </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="logo-type-categ-title">
                                        <div class="categ-title">Adobe Illustrator file</div>
                                        <div class="categ-title-links">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/raphael-labs/ai/B_W_Raphael_Labs_logo_large.ai')
                          " download>RL-BW.ai </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/raphael-labs/ai/Raphael_Labs_logo_large_red_blue.ai')
                          " download>RL-lg-GREY.ai </a>
                                            </div>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a target="_blank" :href="
                            require('../assets/img/mediakit/logos/raphael-labs/ai/W_O_Raphael_Labs_logo_large_red_blue.ai')
                          " download>RL-lg-red-blue.ai </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="logo-type-categ-title">
                                        <div class="categ-title">Logo Guidelines</div>
                                        <div class="categ-title-links">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
                                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                    <polyline points="7 10 12 15 17 10"></polyline>
                                                    <line x1="12" y1="15" x2="12" y2="3"></line>
                                                </svg>
                                                <a href="../pdf/Raphael-Labs-Logo-Guidelines-December-2021.pdf" download>Rl-december-2021.pdf </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="products-wrapper">
                  <div class="section-meta">
                    <h2 class="title">Products</h2>
                    <div class="title-action">View photo stream</div>
                  </div>
                  <div class="section-content">
                    <agile
                      :slidesToShow="3"
                      ref="carousel2"
                      :navButtons="false"
                      :options="optionsProductSlider"
                    >
                      <div class="slide">
                        <div class="product-slide-wrap">
                          <div>lorem</div>
                          <div>lorem</div>
                        </div>
                      </div>
                      <div class="slide">
                        <div class="product-slide-wrap">
                          <div>lorem</div>
                          <div>lorem</div>
                        </div>
                      </div>
                      <div class="slide">
                        <div class="product-slide-wrap">
                          <div>lorem</div>
                          <div>lorem</div>
                        </div>
                      </div>
                      <div class="slide">
                        <div class="product-slide-wrap">
                          <div>lorem</div>
                          <div>lorem</div>
                        </div>
                      </div>
                      <div class="slide">
                        <div class="product-slide-wrap">
                          <div>lorem</div>
                          <div>lorem</div>
                        </div>
                      </div>
                      <div class="slide">
                        <div class="product-slide-wrap">
                          <div>lorem</div>
                          <div>lorem</div>
                        </div>
                      </div>
                      <div class="slide">
                        <div class="product-slide-wrap">
                          <div>lorem</div>
                          <div>lorem</div>
                        </div>
                      </div>
                      <div class="slide">
                        <div class="product-slide-wrap">
                          <div>lorem</div>
                          <div>lorem</div>
                        </div>
                      </div>
                    </agile>
                  </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import {VueAgile} from "vue-agile";

export default {
    name: "Home",
    components: {
        agile: VueAgile,
    },
    data: function () {
        return {
            slideNumber: 1,
            slidesMembers: [
                {
                    title: "Prof. Dame Kay Davies, CBE, FMedSci, FRS",
                    body:
                        "Dr Lee’s Professor of Anatomy at Oxford University,  Co-Director of MDUK Oxford Neuromuscular Centre and member of GRL Board at The Sanger Institute.",
                    photo: "professor-davis",
                    photoBw: "professor-davis-bw",
                    photoColour: "professor-davis-colour",
                },
                {
                    title: "Prof. Rakesh Uppal",
                    body:
                        "Professor of Cardiovascular Surgery at QMUL, Consultant Cardiac Surgeon at Barts Heart Centre and Director of Barts Life Sciences",
                    photo: "professor-rakesh",
                    photoBw: "professor-rakesh-bw",
                    photoColour: "professor-rakesh-colour",
                },
                {
                    title: "Prof. Steve Davies",
                    body:
                        "Waynflete Professor Emeritus of Chemistry at Oxford University; founder of multiple bioscience companies, including Summit Therapeutics and Oxstem Ltd.",
                    photo: "professor-steve",
                    photoBw: "professor-steve-bw",
                    photoColour: "professor-steve-colour",
                },
                {
                    title: "Prof. Angela Russell",
                    body:
                        "Professor of Medicinal Chemistry at University of Oxford. She has founded two bioscience companies to translate her research towards new treatments.",
                    photo: "professor-angela-russell",
                    photoBw: "professor-angela-russell-bw",
                    photoColour: "professor-angela-russell-colour",
                },
                {
                    title: "Prof. Áine McKnight",
                    body:
                        "Professor of Viral Pathology at The Blizard Institute at QMUL, with over thirty years’ experience in molecular virology.",
                    photo: "professor-mck",
                    photoBw: "professor-mck-bw",
                    photoColour: "professor-mck-colour",

                }
            ],
            showCurrentSlide: function (event) {
                return (this.slideNumber = event.currentSlide + 1);
            },
            getImgUrl(member) {
                var images = require.context(
                    "../assets/img/mediakit",
                    false,
                    /\.jpeg$/
                );
                var completeImage = images("./" + member + ".jpeg");
                return completeImage;
            },
            optionsProductSlider: {
                responsive: [
                    {
                        breakpoint: 200,
                        settings: {
                            slidesToShow: 1,
                        },
                    },

                    {
                        breakpoint: 900,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                ],
            },
        };
    },
};
</script>
<style scoped lang="scss">
.type-b {
    .logo-container-type-b {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        max-width: 31%;
    }

    .logo-type-categ-title {
        margin-top: 14px;

        a {
            color: black;
            margin-top: 10px;
            display: block;
            line-height: 100%;
            padding-left: 30px;
            text-decoration: none;
            margin-top: 20px;
            font-size: 14px;
            word-break: break-all;
        }

        a:hover {
            color: #2c6ba4;
        }

        .categ-title-links > div {
            position: relative;
        }

        .categ-title-links svg {
            position: absolute;
            left: 0;
            top: -5px;
            max-width: 20px;
            height: auto;
        }
    }

    .categ-title {
        color: #2c6ba4;
        text-align: left;
        margin-top: 40px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #2c6ba4;
    }
}

.arrow-nav-buttons,
.arrow-nav-buttons-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: space-between;

    .show-slides-number {
        margin-left: 70px;
        margin-right: 70px;
    }
}

.arrow-nav-buttons-bottom > div:first-of-type {
    margin-right: 190px;
}

.nav-arrow-button:hover {
    cursor: pointer;

    circle {
        fill: #2c6ba4;
    }

    path {
        stroke: white;
    }
}

.professor-photo {
    position: relative;
    font-size: 0;

    .professor-photo-links {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        padding: 10px 15px;
        text-align: center;
        color: white;
        font-size: 16px;

        a {
            color: white;
            text-decoration: none;
            font-size: 16px;
        }

        a:hover {
            color: blue;
        }
    }
}

.product-slide-wrap {
    display: flex;
    flex-flow: column;
    // max-width: 31%;
    width: 100%;
    align-content: center;
    align-items: center;

    div {
        height: 163px;
        width: 95%;
        max-width: 100%;
        background-color: #dadada;
        margin: 0 auto;
        margin-bottom: 10px;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.section-meta {
    background: #2c6ba4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    min-height: 40px;
    padding: 10px 15px;
}

.section-content {
    padding: 30px 28px;
}

.title-action {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 15px;
}

h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 31px;
    color: #9fa09f;
    margin-top: 63px;
    margin-bottom: 36px;
}

h2 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    margin-top: 0;
    margin-bottom: 0;
}

.media-kit-page {
    padding: 0 100px;
    text-align: left;
    margin-bottom: 90px;
}

.main-content-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.main-content-wrapper > div {
    max-width: 48%;
    width: 100%;
    margin-bottom: 43px;
    border: 1px solid #e2e2e2;
    // padding: 30px 28px;
}

.logo-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 103% */

    letter-spacing: -0.02em;
    margin-bottom: 7px;
    color: #0c0824;
}

.logo-types {
    font-style: normal;
    font-size: 13.5px;
    line-height: 18px;
    /* identical to box height, or 137% */

    letter-spacing: -0.02em;

    color: #0c0824;
}

.logo-types > div {
    margin-bottom: 4px;
}

.logo-types > div:last-of-type {
    margin-bottom: 0;
}

.logo-img {
    background: #dadada;
    border-radius: 3px;
    width: 100%;
    height: 163px;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 0;
    margin-bottom: 12px;

    img {
        padding: 10px 20px;
        max-width: 161px;
    }
}

.logos-wrapper .section-content {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.logos-wrapper .logo-container {
    max-width: 32%;
    width: 100%;
    text-align: left;
}

.download-logo {
    background: #2c6ba4;
    border-radius: 3px;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 13.5px;
    line-height: 18px;
    /* identical to box height, or 137% */
    letter-spacing: -0.02em;
    color: #ffffff;
    display: block;
    text-align: center;
    text-decoration: none;
    margin-top: 13px;
    padding: 5px 10px;
}

@media screen and (max-width: 1100px) {
    .media-kit-page {
        padding: 0 40px;
    }
    .main-content-wrapper > div {
        max-width: 100%;
    }
    .logos-wrapper .logo-container {
        margin-bottom: 40px;
        max-width: 100%;
    }
}
</style>
<style lang="css">
.professor-photo-download {
    font-weight: 700;
}

.professor-photo-download:hover {
    color: #2c6ba4 !important;
}

.agile__dot {
    margin: 0 3px;
}

.agile__dot button {
    background-color: #c4c4c4;
    border-radius: 100%;
    width: 7px;
    height: 12px;
    border: none;
}

.agile__dot button:focus {
    outline: none;
}

.agile__dot--current.agile__dot button {
    background-color: #2c6ba4;
}

.professor-photo img {
    width: 100%;
    object-fit: cover;
    min-height: 550px;
}

@media screen and (max-width: 1550px) {
    .type-b .logo-container-type-b {
        max-width: 48% !important;
        margin-bottom: 40px !important;
    }
}

@media screen and (max-width: 650px) {
    .professor-photo img {
        min-height: 300px;
    }

    .arrow-nav-buttons .show-slides-number {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }

    .logos-wrapper .section-content {
        flex-flow: column;
    }

    .type-b .logo-container-type-b {
        max-width: 100% !important;
    }
}
</style>
