<template>
	<div class="page">
		<div class="news-page">
			<h3>News and Media</h3>

			<div class="news" style="max-width: 800px;">

				<a class="news-item" href="https://indianexpress.com/article/lifestyle/health/covid-nasal-sprays-prevent-treat-infection-science-8295790/" target="_blank">Indian Express: COVID nasal sprays may one day prevent and treat infection. Here’s where the science is up to</a><span class="small-date">30.11.2022</span>

				<a class="news-item" href="https://m.economictimes.com/magazines/panache/covid-nasal-sprays-could-one-day-stop-the-virus-from-replicating-or-entering-the-body/articleshow/95843549.cms" target="_blank">Economic Times: Covid nasal sprays could one day stop the virus from replicating, or entering the body</a><span class="small-date">29.11.2022</span>

				<a class="news-item" href="https://www.abc.net.au/news/2022-11-29/covid-nasal-spray-treat-and-prevent-infection-replace-vaccine/101706232" target="_blank">ABC News: COVID-19 nasal sprays may one day prevent and treat infection. Here’s where the science is up to</a><span class="small-date">28.11.2022</span>

				<a class="news-item" href="https://knowridge.com/2022/11/new-nasal-spray-can-lower-covid-19-infection-by-62/" target="_blank">Knowridge: New nasal spray can lower COVID-19 infection by 62%</a><span class="small-date">28.11.2022</span>

				<a class="news-item" href="https://covid19.onedaymd.com/2022/10/can-nasal-sprays-treat-or-prevent-covid.html" target="_blank">OneDayMD: "Can Nasal Sprays Treat or Prevent COVID-19?"</a><span class="small-date">26.10.2022</span>

				<a class="news-item" href="https://www.bio.org/policy/human-health/vaccines-biodefense/coronavirus/pipeline-tracker" target="_blank">Bio - Technology Innovation Organization: pHOXWELL is included in the "Most Advanced COVID-19 Antiviral Candidates"</a><span class="small-date">17.10.2022</span>

				<a class="news-item" href="https://pubmed.ncbi.nlm.nih.gov/35952426/" target="_blank">PubMed archive: Evaluating the efficacy and safety of a novel prophylactic nasal spray in the prevention of SARS-CoV-2 infection: A multi-centre, double blind, placebo-controlled, randomised trial</a><span class="small-date">18.09.2022</span>

				<a class="news-item" href="https://www.sjc.ox.ac.uk/discover/news/professor-angela-russell-spearheads-novel-treatments-for-covid-and-duchenne-muscular-dystrophy/" target="_blank">
					St John’s College, Oxford University:
					Prof. Angela Russell spearheads novel treatments for COVID and Duchenne Muscular Dystrophy</a><span class="small-date">01.09.2022</span>

				<a class="news-item" href="https://www.elle.fr/Societe/News/Covid-19-un-spray-nasal-pourrait-il-reduire-l-infection-4043463" target="_blank">
					Elle: Covid-19 - un spray nasal pourrait-il réduire l’infection ?</a> <span>08.08.2022</span>

				<a class="news-item" href="https://newsinfo.com.ng/oxford-scientists-develop-an-inexpensive-nasal-spray-that-reduces-covid-risk-by-62/" target="_blank">
					Oxford scientists develop an inexpensive nasal spray that reduces COVID risk by 62%</a><span class="small-date">07.08.2022</span>

				<a class="news-item" href="https://www.freethink.com/health/nasal-spray-phoxwell" target="_blank">
					Freethink: A cheap nasal spray cuts Covid-19 risk by 62%</a> <span>03.08.2022</span>

				<a class="news-item" href="https://www.qmul.ac.uk/media/news/2022/smd/new-trial-shows-nasal-spray-reduces-infection-of-covid-causing-virus-by-62-.html" target="_blank">
					New trial shows nasal spray reduces infection of covid-causing virus by 62%</a><span class="small-date">01.08.2022</span>

				<a class="news-item" href="https://www.bartshealth.nhs.uk/news/barts-health-researchers-show-nasal-spray-can-prevent-covid19-13716" target="_blank">
					Barts Health researchers show nasal spray can prevent Covid-19</a> <span>29.07.2022</span>

				<a class="news-item" href="https://www.researchprofessionalnews.com/rr-news-uk-innovation-2022-7-trial-shows-uk-designed-nose-spray-can-prevent-covid-19/" target="_blank">
					Research Professional News: Trial shows UK-designed nose spray can prevent Covid-19.</a> <span>28.07.2022</span>

				<router-link class="news-item" :to="{ name: 'phoxwell-paper-press-release-july-26th' }">Raphael Labs paper has been published online in the Journal of Clinical Virology, entitled “Evaluating the efficacy and safety of a novel prophylactic nasal spray in the prevention of SARS-CoV-2 infection: A multi-centre, double blind, placebo-controlled, randomised trial”.</router-link>
				<span>26.07.2022</span>
				<span style="font-size: 75%"><a href="https://raphaellabs.com/pr/pHOXWELL_Paper_Press_Release_July_26th.pdf">PDF</a></span>

				<a class="news-item" href="https://www.euronews.com/next/2021/12/29/from-vaccines-to-hands-free-shoes-these-were-the-top-inventions-and-innovations-of-2021" target="_blank">
					EURONEWS: From vaccines to hands-free shoes: These were the top inventions and innovations of 2021</a> <span>29.12.2021</span>

				<a class="news-item" href="https://www.euronews.com/next/2021/12/22/covid-still-dominated-2021-here-s-how-new-technology-helped-fight-the-virus-this-year" target="_blank">
					EURONEWS: COVID still dominated 2021. Here’s how new technology helped fight the virus this year</a> <span>23.12.2021</span>

				<a class="news-item" href="https://www.elindependiente.com/vida-sana/salud/2021/12/06/el-spray-nasal-que-podria-prevenir-el-contagio-de-los-virus-respiratorios/" target="_blank">
					EL INDEPENDIENTE: El spray nasal que podría prevenir el contagio de los virus respiratorios | The nasal spray that
					could prevent the spread of respiratory viruses </a> <span>07.12.2021</span>

				<a class="news-item" href="https://www.euronews.com/next/2021/11/27/could-this-nasal-spray-be-the-solution-to-preventing-and-treating-all-covid-variants" target="_blank">
					EURONEWS: Could this nasal spray be the solution to preventing and treating all COVID variants? </a>
				<span>29.11.2021</span>

				<a class="news-item" href="https://www.irishmirror.ie/news/scientists-begin-tests-covid-nasal-25568871" target="_blank">
					Irish Mirror: Scientists begin tests on Covid nasal spray with 'equal efficacy' against all variants </a>
				<span>29.11.2021</span>

				<a class="news-item" href="https://www.forbes.com/sites/aayushipratap/2021/11/08/this-company-has-applied-for-authorization-in-india-for-its-nasal-spray-that-prevents-covid/" target="_blank">
					Forbes: This Company Has Applied For Authorization In India For Its Nasal Spray That Prevents
					Covid </a> <span>08.11.2021</span>

				<router-link class="news-item" :to="{ name: 'raphael-labs-cm-press-release-2-11-21' }">RAPHAEL LABS (RL) AND VINGROUP ANNOUNCE WORKING AGREEMENT TO BRING ANTI-SARS-CoV-2 NASAL SPRAY TO VIETNAM</router-link>
				<span>02.11.2021</span>
				<span style="font-size: 75%"><a href="https://pr.phoxbio.com/Raphael_Labs_CM_Press_Release_2_11_21.pdf">PDF</a></span>

				<a class="news-item" href="https://www.clinicaltrialsarena.com/news/phoxbio-preventive-covid-19-nasal-spray/" target="_blank">
					CLINICAL TRIALS ARENA: PHOXBIO eyes UK study of preventive Covid-19 nasal spray by year’s end </a>
				<span>12.10.2021</span>

				<a class="news-item" href="https://www.emergingrisks.co.uk/covid-nasal-protection-spray-a-game-changer/" target="_blank">
					Emerging Risks: Covid nasal protection spray a game changer</a> <span>01.10.2021</span>

				<a class="news-item" href="https://www.itv.com/thismorning/articles/could-a-nasal-spray-cut-your-chances-of-contracting-covid-dr-mark-porter-is" target="_blank">
					ITV’s This Morning - Could a nasal spray cut your chances of contracting Covid? </a>
				<span>28.09.2021</span>

				<a class="news-item" href="https://english.aawsat.com/home/article/3214136/british-scientists-develop-nasal-spray-help-cut-covid-risks" target="_blank">
					ASHARQ-AL-AWSAT: British Scientists Develop Nasal Spray to Help Cut Covid Risks </a> <span>28.09.2021</span>

				<a class="news-item" href="https://www.easterneye.biz/new-anti-viral-nasal-spray-cuts-the-chance-of-catching-covid/" target="_blank">
					Eastern Eye: New anti-viral nasal spray cuts the chance of catching Covid </a> <span>27.09.2021</span>

				<a class="news-item" href="https://bartslifesciences.org/queen-mary-researchers-including-director-of-barts-life-sciences-rakesh-uppal-help-develop-nasal-spray-that-prevents-covid-19/" target="_blank">
					Queen Mary researchers including Director of Barts Life Sciences Rakesh Uppal help develop nasal spray that prevents Covid-19
				</a>
				<span class="small-date">27.09.2021</span>

				<a class="news-item" href="https://www.qmul.ac.uk/media/news/2021/smd/queen-mary-researchers-help-develop-nasal-spray-that-prevents-covid-19.html" target="_blank">
					Queen Mary researchers help develop nasal spray that prevents Covid-19 </a>
				<span>27.09.2021</span>

				<a class="news-item" href="https://www.dailymail.co.uk/news/article-10029007/Invented-Britain-New-anti-viral-nasal-spray-dramatically-cuts-chance-catching-Covid.html" target="_blank">
					Mail on Sunday: New anti-viral nasal spray that dramatically cuts the chance of catching Covid </a>
				<span>26.09.2021</span>

				<a class="news-item" href="https://www.biospace.com/article/two-intranasal-candidates-get-closer-to-augmenting-pandemic-response/" target="_blank">
					BioSpace: Nasal Spray Excites in Phase II/III </a> <span>22.09.2021</span>


				<router-link class="news-item" :to="{ name: 'phoxbio-cm-press-release-web-22-9-21' }">pHOXBIO ANNOUNCES BREAKTHROUGH CLINICAL TRIAL RESULTS CONCLUDING PROPHYLACTIC NASAL SPRAY PREVENTS INFECTION FROM SARS-CoV-2.</router-link>
				<span>22.09.2021</span>
				<span style="font-size: 75%"><a href="https://pr.phoxbio.com/pHOXBIO_CM_Press_Release_web_22_9_21.pdf">PDF</a></span>

			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer-nav {
	position: absolute;
	width: 100%;
	bottom: 0;
}

.news-page {
	padding: 0 100px;
	text-align: left;
}

.news-item {
	display: block;
	width: 100%;
	margin-top: 25px;
}

h3 {
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 31px;
	color: #9fa09f;
	margin-top: 63px;
	margin-bottom: 36px;
}

.news {
	margin-bottom: 44px;

	span {
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		display: block;
		color: #9fa09f;
	}

	p {
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		line-height: 20px;
		color: #0c0824;
		margin-bottom: 8px;
	}

	a {
		font-weight: 300;
		font-size: 17px;
		line-height: 20px;
		text-decoration-line: underline;
		color: #0c0824;
	}
}

@media screen and (max-width: 1100px) {
	.news-page {
		padding: 0 40px;
	}
}

@media screen and (max-width: 560px) {
	.news-page {
		padding: 0 24px;
	}
	h3 {
		font-size: 28px;
		margin-top: 47px;
		line-height: 34px;
	}
}
</style>
