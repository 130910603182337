<template>
	<div class="page team">
		<div class="intro">
			<h3>Team</h3>
			<p>
				pHOXBIO is led by a strong management team with a diverse background and deep experience in the
				biopharmaceutical industry, academia and commerce. </p>
		</div>
		<div class="members-grid">

			<div class="member">
				<div class="member-img">
					<img src="../assets/img/team/steve.png" alt="Prof. Steve Davies "/>
				</div>
				<p>
					<b>Prof. Steve Davies</b> Waynflete Professor Emeritus of Chemistry, Oxford University; founded and
					invested in several companies, including Oxford Asymmetry Ltd and Oxford Diversity Ltd (combined and
					sold to Evotec in 2000 at a valuation of £316m), NASDAQ listed Summit Therapeutics Inc and Oxstem
					Ltd where he heads a multidisciplinary research team in regenerative medicine at Oxford. </p>
			</div>

			<div class="member">
				<div class="member-img">
					<img src="../assets/img/team/upal.png" alt="Rakesh Uppal"/>
				</div>
				<p>
					<b>Prof. Rakesh Uppal</b> Consultant Cardiac Surgeon at Barts Heart Centre and Professor of
					Cardiovascular Surgery at QMUL. He is the Director of Barts Life Sciences, a collaboration between
					academia, clinicians and industry to transform patient care. He is currently working on stem cell
					therapy for the failing heart and understanding the genetic basis of cell senescence in acute renal
					injury following cardiac interventions. </p>
			</div>

			<div class="member">
				<div class="member-img">
					<img src="../assets/img/team/russel.png" alt="Angela Russell"/>
				</div>
				<p>
					<b>Prof. Angela Russell</b> Professor of Medicinal Chemistry at the University of Oxford. She has
					founded two companies MuOx Ltd (acquired by Summit Therapeutics plc) and Oxstem Ltd to translate her
					research towards new treatments. She currently leads a multidisciplinary research team developing
					new therapies for degenerative diseases at Oxford. </p>
			</div>

			<div class="member">
				<div class="member-img">
					<img src="../assets/img/team/aine.png" alt="Professor Áine McKnight"/>
				</div>
				<p>
					<b>Professor Áine McKnight</b> Professor of Viral Pathology at the Blizard Institute, Barts and The
					London School of Medicine and Dentistry, Queen Mary University of London. Professor McKnight has
					dedicated her career to HIV/AIDS research. Her current research group is focused on novel host cell
					restriction factors that inhibit HIV replication. During the Covid-19 pandemic she has repurposed
					her skills to investigate the immune responses to SARS-CoV-2 in natural infection and after
					vaccination. </p>
			</div>

			<div class="member">
				<div class="member-img">
					<img src="../assets/img/team/davies.png" alt="Dame Kay Davies"/>
				</div>
				<p>
					<b>Prof. Dame Kay Davies, CBE</b> FMedSci, Dr Lee’s Professor of Anatomy at the University of Oxford
					and Co-Director of the MDUK Oxford Neuromuscular Centre. She has co-founded companies to translate
					her work to the clinic. She was Deputy Chair of Wellcome (2013-17), chairs the Genomics England
					Clinical Interpretation Partnership and is a member of the GRL Board at the Sanger Institute. </p>
			</div>

			<div class="member">
				<div class="member-img">
					<img src="../assets/img/team/silpa.png" alt="Laurence Silpa"/>
				</div>
				<p>
					<b>Dr. Laurence Silpa</b> Doctorate in Medicinal Chemistry, University of Tours and the Institut National de
					Recherche Agronomique. Joined Professor Angela Russell’s team 2015. Post-doctoral positions in
					collaboration with StemBANCC’s consortium, an academic- industry partnership in the area of stem
					cell research and Oxstem Ltd, a regenerative medicine drug discovery company. </p>
			</div>

			<div class="member">
				<div class="member-img">
					<img src="../assets/img/team/rann.png" alt="Mike Rann"/>
				</div>
				<p>
					<b>The Hon Mike Rann</b> AC CNZM Former Premier of South Australia (2002-2011).
					Former Australian High Commissioner to the UK and later Ambassador to Italy.
					Chair of the Climate Group (UK). Visiting Professor at the
					Policy Institute of King’s College London.
				</p>
			</div>

			<div class="member">
				<div class="member-img">
					<img src="../assets/img/team/john.png" alt="John Mara"/>
				</div>
				<p>
					<b>John Mara</b> Investor with significant hands-on experience in acquiring and building profitable
					start-up businesses internationally. Expertise across TMT, FinTech, manufacturing and
					aerospace/defence. </p>
			</div>
			<div class="member">
				<div class="member-img">
					<img src="../assets/img/team/enda.png" alt="Enda O’Connell"/>
				</div>
				<p>
					<b>Enda O’Connell</b> Experienced Chartered Accountant with over 10 years’ experience managing
					businesses across a number of jurisdictions with overall asset values of €100m +. </p>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: "Team",
};
</script>

<style scoped lang="scss">
.page.team {
	padding: 53px 100px;
}

.intro {
	text-align: left;

	h3 {
		margin-bottom: 40px;
		font-weight: 300;
		font-size: 38px;
		line-height: 46px;
		color: #807f80;
		margin-top: 0;
	}

	p {
		font-style: normal;
		font-weight: 300;
		font-size: 17.5px;
		line-height: 20px;
		color: #807f80;
		max-width: 602px;
		margin-bottom: 57px;
	}
}

.members-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 37px;
	row-gap: 37px;

	.member {
		text-align: left;

		&.consultants {
			p {
				margin-bottom: 14px;
			}

			img {
				display: block;
				margin-bottom: 16px;
				width: 139px;
			}
		}

		.member-img {
			margin-right: 12px;
			float: left;

			img {
				width: 101px;
				height: 101px;
				border-radius: 3px;
			}
		}

		p {
			font-weight: 300;
			font-size: 13.5px;
			line-height: 18px;
			letter-spacing: -0.02em;
			color: #0c0824;
			margin: 0;
			margin-bottom: 0;
		}
	}
}

@media screen and (max-width: 1000px) {
	.page.team {
		padding: 53px 40px;
	}
}

@media screen and (max-width: 900px) {
	.members-grid {
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 630px) {
	.page.team {
		padding: 43px 24px;
	}
	.intro {
		h3 {
			font-size: 28px;
			line-height: 34px;
			margin-bottom: 23px;
		}

		p {
			margin-bottom: 30px;
		}
	}
	.members-grid {
		grid-template-columns: 1fr;
		gap: 38px;

		.member p {
			font-size: 17.5px;
			line-height: 22px;
		}
	}
}
</style>
