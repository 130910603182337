<template>
	<div class="page">
		<div class="news-page">

			<div class="news" style="max-width: 800px;">


				<h2 style="margin-bottom: 30px;">
					PHOXBIO ANNOUNCES BREAKTHROUGH CLINICAL TRIAL RESULTS CONCLUDING PROPHYLACTIC NASAL SPRAY PREVENTS
					INFECTION FROM SARS-CoV-2. </h2>

				<ul>
					<li>pHOXWELL significantly reduced SARS-CoV-2 infection by 63% with tolerability comparable to
						placebo in high-risk population of healthcare workers
					</li>
					<li>Novel self-administered nasal spray designed to augment existing preventive measures for
						SARS-CoV-2, including PPE and vaccines
					</li>
					<li>Regulatory filing initiated in India for SARS-CoV-2 prevention claim</li>
					<li>Company exploring strategic alternatives to make product accessible in India and markets
						worldwide to help combat ongoing COVID-19 pandemic and future disease outbreaks
					</li>
				</ul>

				<p><b>London and New York, September 22, 2021</b> — pHOXBIO Ltd. today announced results from a
					randomized, double-blind, placebo-controlled clinical trial which demonstrate that pHOXWELL, its
					novel prophylactic nasal spray, prevented infection from SARS-CoV-2, the virus that causes COVID-19.
					In a pivotal Phase 2/3 clinical study, there were 63% fewer SARS-CoV-2 infections in high-risk
					healthcare workers given pHOXWELL compared to placebo (p=&lt;0.0001).</p>


				<p>pHOXWELL is a self-administered prophylactic nasal spray designed to offer a variant-agnostic
					mechanism of action that provides a robust defence to inhibit the infection processes of SARS-CoV-2.
					It is designed to be effective against other airborne respiratory viruses. The product offers 6-8
					hours of protection with just two sprays per nostril, per application, and can be applied whether in
					the workplace, at home or “on-the-go”</p>


				<p>Company Chairman, Professor Rakesh Uppal, Professor of Cardiovascular Surgery at Queen Mary
					University of London and Director of Barts Life Sciences, says “pHOXWELL presents a significant
					breakthrough. We now have an effective tool, previously missing, to fight this pandemic.
					Vaccination, while absolutely essential, is not 100% effective and it is still possible to become
					infected by, and transmit, the virus that causes COVID-19. pHOXWELL is designed to offer extra
					protection to vaccines and PPE, as the spray inhibits SARS-CoV-2 from infecting the nasal mucosa,
					which is the primary entry point into the body. pHOXWELL’s efficacy is likely to be maintained with
					future mutations in the virus”</p>

				<b>Study Design and Results</b>
				<p>
					The study was a double-blind, randomized, placebo-controlled study to assess the efficacy and safety
					of pHOXWELL nasal spray in the prevention of SARS-CoV-2 infection in high-risk healthcare
					professionals in India. The trial was carried out during the peak surge of the highly infectious
					Delta variant in India in April to July 2021. The study was designed following approval by the
					appropriate regulator. The primary end point was the percentage of subjects who test positive for
					SARS-CoV-2 on IgGS (spike protein specific) testing over the 45 days of the study. Secondary end
					points included efficacy, safety and tolerability measures </p>

				<p>The primary end point showed that 13.1% of subjects were IgGS positive in the pHOXWELL arm versus
					34.5% in the placebo arm (p=&lt;0.0001). This result shows that pHOXWELL has a significant
					prophylactic effect versus SARS-CoV-2 infection when compared to placebo. These highly statistically
					significant results were consistent across the two sites which recruited subjects (Site 1 17.4% vs
					54.6%, p=&lt;0.0001; Site 2 11.1% vs 23.9%, p=0.0015) and sex (male 13.6% vs 36.6%, p=&lt;0.0001; female
					12.1% vs 31.2%, p=0.0013) </p>

				<p>The secondary end point looking at subjects experiencing clinical symptoms, also shows significant
					results in favour of pHOXWELL, with only 17.6% of subjects who experienced infection in the pHOXWELL
					arm having clinical symptoms versus 34.6% in the placebo arm (p=&lt;0.0001). These results were
					consistent across sex (male 15.3% vs 31.8%, p=0.0001; female 21.7% vs 39%, p=0.0048) and age groups
					(18-35 26.5% vs 43.6%, p=0.0091; 36-65 19.4% vs 36.9% p=0.0018; 65+ 6.9% vs 33.3%, p=0.0407).</p>


				<p>User acceptability end points were positive, with an overall positive experience maintained across
					the study. pHOXWELL also exhibited an excellent safety profile.</p>


				<p>648 subjects completed the study. The mean age was 40.8 years. 63.3% of subjects were male and 36.7%
					female. All subjects were over 18, unvaccinated, demonstrated not to be infected with SARS-COV-2 at
					the time of entry (RT-PCR), and not to have had any previous infections (IgG Spike protein
					negative). They were treated three times a day with active or placebo prior to any possible exposure
					situation to SARS-CoV-2 over 45 days. Subjects were regularly tested by RT-PCR for SARS-CoV-2
					infection, 556 had IgGS testing at the last visit and potential symptoms and adverse events were
					recorded. A technical issue during the study caused RT-PCR testing not to work for the infection end
					point for all subjects, hence the use of IgGS testing</p>


				<b>Company Seeking To Deliver Benefits to Society</b>
				<p>pHOXBIO and its parent company, Raphael Labs, will now initiate a regulatory filing to support a
					SARS-CoV-2 prevention claim with the appropriate regulator, based on the clinical trial data. This
					will allow for production and distribution of pHOXWELL in India initially, with further territories
					intended to follow as a prophylaxis against SARS-CoV-2 infections.</p>


				<p>Professor Uppal, said “there is a pressing need globally for a prophylactic nasal spray to help
					prevent infection in areas where vaccination rollout remains inadequate in the face of the tragic
					human toll of this pandemic. We are confident that our anti-COVID-19 nasal spray, pHOXWELL, will
					become a vital part of the global armoury to provide an extra defensive shield to tackle the
					pandemic, and we welcome forming partnerships with governments, NGOs and manufacturers to deliver
					the preventative benefits of pHOXWELL”</p>

				<p>pHOXWELL is low cost, easy to manufacture, shown to have prolonged stability at room temperature and
					can be transported globally. We anticipate its use will be applicable to many populations where
					vaccination rates remain low and PPE is scarce, particularly for frontline health workers. pHOXBIO
					is seeking to evaluate opportunities that can help accelerate the delivery of its products’ benefits
					to society. Interested parties can contact the company at <a href="https://phoxbio.com/#/contact">https://phoxbio.com/#/contact</a>.
				</p>


				<b>About pHOXWELL</b>
				<p>pHOXWELL is a combination of natural virucides and our novel scientific platform which work in
					concert to prevent viral infection. pHOXWELL is designed to provide additional protection that
					complements current standards of care in inhibiting the spread of airborne respiratory viruses,
					including PPE and vaccines. pHOXWELL is virucidal and has been designed to inhibit the infection of
					other airborne respiratory viruses including common and new strains of coronaviruses, influenzas and
					rhinoviruses. In vitro testing confirmed that pHOXWELL killed 90% of H1N1 (Influenza) in under 60
					seconds</p>

				<p>pHOXWELL offers 6-8 hours of protection with just two sprays per application and can be applied
					whether at home, at work or “on the go”. pHOXWELL can be used by most people, whether vaccinated or
					not, and is aimed at adults ages 18 and over</p>


				<b>Airborne Respiratory Viruses & Current Gaps</b>
				<p>Viruses are amongst the most infectious and debilitating airborne respiratory pathogens. Even without
					SARS-CoV-2’s devastating impact, airborne respiratory pathogen spread contributes to respiratory
					infection and cause around 4 million preventable deaths each year. The ability of vaccines alone to
					control the spread of airborne respiratory viruses is limited by significant challenges including
					variable efficacy and challenges faced by emerging variants, global supply demands and shortages in
					developing countries, and vaccine hesitancy and unsuitability for some.</p>

				<p>PPE use can be insufficient, used incorrectly or malfunction and in some areas is poorly available.
					There is a significant need for an effective and safe prophylactic that compliments vaccines and
					PPE</p>


				<b>About pHOXBIO and Raphael Labs</b>
				<p>pHOXBIO is a privately held biopharmaceutical company that is a division of Raphael Labs. The company
					is developing pHOXWELL and pHOXGEN, two unique solutions targeting prophylaxis of airborne
					respiratory viruses such as coronaviruses and influenza viruses that cause some of the world’s
					deadliest communicable diseases. pHOXBIO products represent a scientific breakthrough with the
					potential to impact public health and strengthen pandemic preparedness by addressing substantial
					gaps in current control measures</p>

				<p>Raphael Labs is a Dublin-based privately held biopharmaceutical company with a significant UK
					operation, managing a diverse portfolio of subsidiaries with specific interests in the deveopment
					and commercialization of Raphael Labs’ proprietary formulation Vita Raphael. Company subsidiaries
					include pHOXBIO, which addresses respiratory diseases; pHOXMETICS, which addresses cosmeceutical
					interests; pHOXHEAL, which is focused on wound care; and a research and development arm called
					pHOXWORX </p>

				<p>Raphael Labs’ scientific leadership team includes Professor Dame Kay Davies, Doctor Lee’s Professor
					of Anatomy, Emeritus at Oxford University; Professor Steve Davies, Waynflete Professor Emeritus of
					Chemistry at Oxford University; Alan Dunton M.D., based in Boston; Mr. Goutham K Gorti, FRCS, based
					in New Jersey; Professor Áine McKnight, Professor of Viral Pathology at the Blizard Institute, Queen
					Mary University of London; Professor Angela Russell, Professor of Medicinal Chemistry at Oxford
					University; Dr Jim Swales, pHOXWELL’s clinical trial lead; Professor Mauro Teixeira, Professor of
					Immunology, Universidade Federal de Minas Gerais, and Professor Rakesh Uppal, Professor of
					Cardiovascular Surgery, William Harvey Research Institute, Queen Mary University London, Barts Heart
					Centre and Director of Barts Life Sciences.</p>

				<p>Raphael Labs is supported by Mark Timney, Graeme Bell, and Michael Blash in the United States</p>

				<b>News release ends</b>


				<b>Media Enquiries</b> <br> Nicola Burdett (UK/India) <br> <a href="nb@raphlabs.com">nb@raphlabs.com</a>
				<br> <br>

				Matt Arrowsmith (US) <br> <a href="mailto:ma@raphlabs.com">ma@raphlabs.com</a> <br><br>

				<b>Business Enquiries</b> <br> Robert Beenstock <br> <a href="rb@raphlabs.com">rb@raphlabs.com</a>
				<a href="partnerships@raphlabs.com">partnerships@raphlabs.com</a>

			</div>

		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer-nav {
	position: absolute;
	width: 100%;
	bottom: 0;
}

.news-page {
	padding: 0 100px;
	text-align: left;
}

.news-item {
	display: block;
	width: 100%;
	margin-top: 25px;
}

h3 {
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 31px;
	color: #9fa09f;
	margin-top: 63px;
	margin-bottom: 36px;
}

.news {
	margin-bottom: 44px;

	span {
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		display: block;
		color: #9fa09f;
	}

	p {
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		line-height: 20px;
		color: #0c0824;
		margin-bottom: 8px;
	}

	a {
		font-weight: 300;
		font-size: 17px;
		line-height: 20px;
		text-decoration-line: underline;
		color: #0c0824;
	}
}

@media screen and (max-width: 1100px) {
	.news-page {
		padding: 0 40px;
	}
}

@media screen and (max-width: 560px) {
	.news-page {
		padding: 0 24px;
	}
	h3 {
		font-size: 28px;
		margin-top: 47px;
		line-height: 34px;
	}
}
</style>
