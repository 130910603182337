<template>
  <div class="page borden-arps">
    <div class="main">
      <div class="left">
        <h5>Effective solutions – breakthrough science with potential for immense benefits to humanity</h5>
        <p class="sub">pHOXBIO is developing two self-administered prophylactic nasal sprays that inhibit the infection of Airborne Respiratory Viruses (ARVs).</p>
        <div class="imgs">
          <div class="el">
            <img src="../assets/img/icons/phoxgen-white.svg" alt="Phoxgen">
            <p>Pharmaceutical product</p>
          </div>
          <div class="el">
            <img src="../assets/img/icons/phoxwell-white.svg" alt="Phoxwell">
            <p>Natural virucides plus our breakthrough science</p>
          </div>
        </div>
        <div class="options">
          <ul>
            <li><p>Self-administered prophylactic nasal sprays offering protection against<br>
              SARS-CoV-2 and many other airborne respiratory viruses</p></li>
            <li><p>Complement PPE and vaccines</p></li>
          </ul>
        </div>
      </div>
      <img class="sol" src="../assets/img/solution-i-1.webp" alt="">
      <img class="sol-mobile" src="../assets/img/human2-mobile_up.webp" alt="">
    </div>
    <div class="section-1">
      <div class="left">
        <h5>Addressing a critical medical need — effective prophylaxis against infection from ARVs</h5>
        <div class="options">
        <ul>
          <li><p>Coronaviruses and inﬂuenza viral infections usually start in the nasal epithelium. <sup>Ref 1</sup></p></li>
          <li><p>Altering the cellular environment in nasal cavity can maintain homeostasis and inhibit viruses from infiltrating host cells. <sup>Ref 2</sup></p></li>
        </ul>
        </div>
      </div>
      <img class="sol" src="../assets/img/human2-mobile.svg" alt="">
      <img class="sol-mobile" src="../assets/img/human2-bottom-01.svg" alt="">
    </div>
    <!-- <div class="section-2" style="background: #CBCCCB;">
      <img src="../assets/img/covid-schema-1.svg" alt="Schema">
      <p>Schematic diagram of SARS-CoV-2 virion and life cycle.</p>
    </div>
    <div class="section-3">
      <div class="left">
        <h5>Schematic diagram of SARS-CoV-2 virion and life cycle. 
          Targets of <b>pHOXGEN</b> and <b>pHOXWELL</b> in the viral life cycle 
          or viral-host interactions are shown in red
        </h5>
        <p>Self-administered prophylaxis nasal sprays offering a simple, virus-agnostic MOA</p>
        <div class="options">
          <ul>
            <li><p>Robust layer of defense mimics natural interstitial ﬂuid surrounding healthy cells </p></li>
            <li><p>Optimize local pH environment in the nasal cavity to maintain homeostasis and prevent ARPs 
              from invading host cells and starting the infection process</p></li>
          </ul>
        </div>
      </div>
      <img src="../assets/img/covid-schema-2.svg" alt="Schema">
    </div> -->
    <!-- <div class="section-4">
      <img src="../assets/img/covid-schema3.png" alt="Schema">
      <p>Schematic diagram of SARS-CoV-2 virion and life cycle. Drugs in clinical 
        trials and their targets in the viral life cyrcle or viral-host interactions are shown in red. </p>
    </div> -->
    <div class="section-more">
      <p>The breakthrough, underpinning science of Raphael Labs (RL) is based on the discoveries <br> of a world-renowned scientific team. </p>

      <p>RL’s holds a unique asset Vita Raphael (VR). All living cells have a fluid 
        surrounding them called  <br> the interstitial fluid. This fluid is composed of minerals, amino acids and 
        proteins necessary to maintain <br>  a balanced healthy cellular environment. <sup>Ref 3</sup> VR is a solution which mimics this fluid encasing 
        cells <br>  and maintains healthy cell function. Comprehensive testing over many years using cell lines, tissues, <br>  organs and 
        animal models have shown the solution is safe to use over extended  storage in cells <br> or nerves and use for transportation 
        of cells and organs. 
        VR is backed by decades of research <br> showing successful pre-clinical evaluation <i>in vitro</i>, <i>in vivo</i> and <i>ex vivo</i>.</p>
    </div>
    <div class="section-raphael">
        <div class="raphael">
          <img class="raph" src="../assets/img/raphael-labs.svg" alt="Raphael Labs Logo">
          <p>pHOXBIO is a subsidiary of Raphael Labs (RL), a special purpose corporation
          that manages and holds equity in its subsidiaries. RL’s portfolio companies
          have varied scientific discovery interests in the respiratory, cosmeceutical,
          wound care and R&D sectors.</p>
          <div class="container">
            <div class="phox">
              <img src="../assets/img/phoxbio.svg" alt="phoxbio">
              <img src="../assets/img/phoxmetics.svg" alt="phoxmetics">
              <img src="../assets/img/phoxheal.svg" alt="phoxheal">
              <img src="../assets/img/phoxworks.svg" alt="phoxworks">
            </div>
          </div>
          <a target="_blank" href="https://raphaellabs.com">Learn more about RL</a>
        </div>
    </div>
    <div class="section-reff" >
      <h5>References</h5>
      <p>1 Richard M. Influenza A viruses are transmitted via the air from the nasal respiratory epithelium of ferrets.<br> Nature Communications 2020; 11(766).<br> 
      2. Gallo O. The central role of the nasal microenvironment in the transmission, modulation, and clinical progression of SARS-CoV-2<br> infection. Mucosal Immunology 2021; 14:305–316.<br> 
      3. Diabetes. Interstitial Fluid. Published: 15 January 2019.<br>
      </p>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
  .main {
    padding: 70px 59px;
    padding-bottom: 17px;
    background-color: #1D71B8;
    background-image: url(../assets/img/solution-i-1.webp);
    background-repeat: no-repeat;
    background-position: right 49px bottom -150px;
    background-size: 690px;
    .sol {
      display: none;
    }
    .left {
      max-width: 800px;
      text-align: left;
      h5 {
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        color: #FFFFFF;
        margin: 0;
      }
      p {
        font-size: 24px;
        line-height: 150%;
        color: #FFFFFF;
        margin: 0;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: normal;
      }
      .sub {
        max-width: 681px;
      }
      .imgs {
        margin-left: 5px;
        gap: 38px;
        display: flex;
        .el {
          width: 173px;
          text-align: center;
          p {
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
            margin: 0;
            margin-top: 15px;
            text-align: center;
          }
        }
      }
      .options {
        max-width: 700px;
        ul {
          padding: 0;
          margin-bottom: 28px;
          list-style-type: none;
          li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 15px;
            p {
              margin: 0;
              font-weight: normal;
              font-size: 18px;
              line-height: 150%;
              color: #FFFFFF;
            }
            &:before {
              position: absolute;
              width: 8px;
              height: 8px;
              background: #000000;
              content: '';
              left: 0;
              top: 7px;
            }
          }
        }
      }
    }
  }
  .section-1 {
    padding: 70px 59px;
    padding-bottom: 17px;
    background-color: white;
    background-image: url(../assets/img/science-virus-d-01-01.svg);
    background-repeat: no-repeat;
    background-position: right 49px bottom 0px;
    background-size: 690px;
    height: 475px;
    .sol {
      display: none;
    }
    .left {
      max-width: 650px;
      text-align: left;
      h5 {
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 26px;
        color: #1C1339;
        margin: 0;
      }
      .options {
        margin-top: 45px;
        max-width: 618px;
        ul {
          padding: 0;
          margin-bottom: 28px;
          list-style-type: none;
          li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 25px;
            p {
              margin: 0;
              font-style: normal;
              font-weight: 300;
              font-size: 20px;
              line-height: 24px;
              color: #1C1339;
            }
            &:before {
              position: absolute;
              width: 8px;
              height: 8px;
              background: #000000;
              content: '';
              left: 0;
              top: 7px;
            }
          }
        }
      }
    }
  }
  .section-2 {
    padding: 70px 59px;
    background-color: #DADADA;
    // background-image: url(../assets/img/covid-schema-1.svg);
    background-repeat: no-repeat;
    background-position: left 80px center;
    display: flex;
    gap: 100px;
    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      max-width: 235px;
      color: #0C0A24;
      text-align: left;
      float: right;
      min-width: 230px;
    }
  }
  .section-4 {
    padding: 70px 59px;
    background: #CBCCCB;
    background-repeat: no-repeat;
    background-position: left 80px center;
    justify-content: space-between;
    display: flex;
    img {
      height: 500px;
    }
    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      width: 290px;
      color: #0C0A24;
      text-align: left;
      float: right;
    }
  }
  .section-more {
    padding: 24px;
    padding-top: 119px;
    padding-bottom: 80px;
    text-align: center;
    background: #0b0239;
    p {
      margin: 0 auto;
      margin-bottom: 40px;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      max-width: 726px;
      text-align: center;
      color: white;
      br {
        display: none;
      }
    }
  }
  .phox {
    margin: 0 auto;
    margin-top: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 700px;
  }
  .raphael {
    padding: 98px 0;
    img.raph {
      margin-bottom: 50px;
      max-width: 250px;
    }
    p{
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      color: #110E29;
      max-width: 707px;
      text-align: center;
      margin: 0 auto;
    }
    a {
      width: 251px;
      height: 49px;
      border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #0C0824;
      margin: 0 auto;
      margin-top: 45px;
      &:hover {
        background: #f3f3f3;
      }
    }
  }
  .section-reff {
    background: #CCD5E3;
    padding: 50px 100px;
    padding-bottom: 70px;
    text-align: left;
    h5 {
      font-style: normal;
      font-weight: 300;
      text-align: left;
      color: #0c0824;
      margin: 0;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 17px;
    }
    p {
      margin: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      color: #0C0824;
      font-size: 12px;
      line-height: 21px;
      font-weight: 300;
    }
    a {
      color: #0C0824;
    }
  }
  .section-3 {
    padding: 70px 59px;
    display: flex;
    gap: 80px;
    justify-content: space-between;
    .left {
      max-width: 290px;
      text-align: left;
      h5 {
        font-weight: normal;
        margin: 0;
        font-size: 20px;
        line-height: 24px;
        color: #0C0A24;
        margin-bottom: 24px;
      }
      p {
        font-weight: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;

        color: #0C0A24;
      }
      .options {
        margin-top: 24px;
        max-width: 470px;
        ul {
          padding: 0;
          margin-bottom: 28px;
          list-style-type: none;
          li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 18px;
            p {
              margin: 0;
              font-weight: 500;
              font-size: 16px;
              line-height: 15px;

              color: #0C0A24;
            }
            &:before {
              position: absolute;
              width: 8px;
              height: 8px;
              background: #000000;
              content: '';
              left: 0;
              top: 4px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1360px) { 
    .section-2, .section-3, .section-4 {
        gap: 10px;
    }
  }
  @media screen and (max-width: 1260px) { 
    .section-2, .section-4 {
      flex-direction: column-reverse;
      p {
        max-width: unset;
        width: unset;
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    .section-3 {
      flex-direction: column;
      .left {
        max-width: unset;
      }
    }
    .main { 
      background-size: 554px;
    }
    .section-1 {
      background-size: 554px;
    }
  }
  @media screen and (max-width: 1050px) { 
    .main {
      padding: 25px 24px;
      height: 1000px;
      .left {
        max-width: unset;
        .imgs {
          // display: grid;
          // align-items: center;
        }
        .sub {
          max-width: unset;
        }
      }
    }
    .section-reff {
      padding: 50px 24px;
    }
    .section-1 {
      padding: 25px 24px;
      height: 750px;
      .left {
        max-width: unset;
        .sub {
          max-width: unset;
        }
      }
    }
    .section-2, .section-3, .section-4  {
      padding: 25px 24px;
    }
  }
  @media screen and (max-width: 768px) {
      .phox {
        margin-top: 59px;
      }
    .raphael {
      padding: 60px 24px;
      p {
        max-width: 600px;
      }
    }
    .main, .section-1 {
      height: 1100px;
      background-image: none;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .sol {
        display: block;
        margin-bottom: -119px;
        margin-top: 39px;
        margin-left: -20px;
        margin-right: -24px;
      }
      .left {
        max-width: unset;
        .imgs {
          margin-bottom: 30px;
          display: flex;
          gap: 0;
          .el {
            display: grid;
            grid-template-columns: 1fr;
            width: 120px;
            margin-right: 20px;
            img {
              margin-bottom: 10px;
              width: 120px;
            }
            p{
              margin: 0;
              font-size: 14px;
              line-height: 130%;
            }
          }
        }
      }
    }
    .section-1 {
      height: unset;
      .sol {
        margin-bottom: -28px;
        margin-left: -25px;
        margin-right: -24px;
      }
    }
  }
  .sol-mobile {
    display: none;
  }

@media screen and (max-width: 670px) {
  .phox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 33px;
    justify-items: center;
  }
}
  @media screen and (max-width: 560px) { 
    .raphael {
      img.raph {
        width: 205px;
        margin-bottom: 40px;
      }
      p {
        font-size: 20px;
        line-height: 150%;
      }
    }
    .main .sol, .section-1 .sol {
      display: block;
      margin-bottom: -161px;
      margin-top: 4px;
      margin-left: -103px;
      margin-right: unset;
      width: 500px;
      display: none;
    }
    .main .sol-mobile {
      display: block;
      overflow: hidden;
      margin-left: -24px;
      margin-bottom: -27px;
      height: auto;
      width: 110%;
    }
    .main {
      height: unset;
    }
    .section-1 {
      height: unset;
      .sol-mobile {
        overflow: hidden;
        display: block;
        margin-left: -24px;
        margin-right: -24px;
        margin-bottom: -27px;
        height: auto;
        width: 115%;
      }
    }
    .main {
      background-size: 321px;
      background-position: right 19px bottom -80px;
      .left {
        h5 {
          font-weight: bold;
          font-size: 22px;
          line-height: 28px;
        }
        p {
          font-weight: normal;
          font-size: 18px;
          line-height: 150%;
        }
      }
    }
    .section-more {
      a {
      margin-top: 66px;
      }
      p {
        br {
          display: none;
        }
      }
    }
  }
</style>
