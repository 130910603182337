<template>
  <link
    rel="stylesheet"
    type="text/css"
    href="https://cloud.typography.com/6284918/6106832/css/fonts.css"
  />
  <Nav />
  <router-view />
  <FooterNav />
</template>

<script>
window._mfq = window._mfq || [];
(function() {
  var mf = document.createElement("script");
  mf.type = "text/javascript";
  mf.defer = true;
  mf.src =
    "//cdn.mouseflow.com/projects/06e164bf-62b7-448d-870b-ca9ad9cb17a7.js";
  document.getElementsByTagName("head")[0].appendChild(mf);
})();

import Nav from "./components/nav.vue";
import FooterNav from "./components/footerNav.vue";

export default {
  name: "App",
  components: {
    Nav,
    FooterNav,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.cdnfonts.com/css/gotham");

* {
  font-family: "Gotham SSm A", "Gotham SSm B", Arial, sans-serif;
}

.no-overflow {
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: auto;
  padding: 0;
}
li,
p {
  sup {
    position: static;
    line-height: 0px;
    position: relative;
    top: 2px;
  }
}

sup {
  font-style: italic;
  font-size: 12px;
}

.container {
  max-width: 1166px;
  margin: 0 auto;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@media screen and (max-width: 1200px) {
  .container {
    max-width: 814px;
  }
}
</style>
