<template>
  <div class="page">
      <div class="contact-page">
        <div class="texts">
            <h3>Contact</h3>
            <p>pHOXBIO is rapidly advancing the development of its breakthrough science to stop 
                the spread of airborne respiratory viruses. 
                We are seeking to evaluate opportunities that can help deliver the benefits of our products to society.</p>
            <p>For questions or inquiries, please contact us at one of the links below.</p>
            <div class="spans">
                <span>General Enquiries: <a href="mailto:info@raphlabs.com">info@raphlabs.com</a></span>
                <span>Clinical Trials: <a href="mailto:clinical@raphlabs.com">clinical@raphlabs.com</a></span>
                <span>Partnerships: <a href="mailto:partnerships@raphlabs.com">partnerships@raphlabs.com</a></span>
                <span>Investors: <a href="mailto:investors@raphlabs.com">investors@raphlabs.com</a></span>
                <span>Media: <a href="mailto:media@raphlabs.com">media@raphlabs.com</a></span>
            </div>
        </div>
        <div class="maps">
            <div class="map">
                <img src="../assets/img/map2.png" alt="Address 2">
                <span>8 Galway Technology Park, Parkmore, Galway, Ireland, H91 Y64C</span>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
.contact-page {
    padding: 0 100px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    margin-bottom: 63px;
    p {
        font-style: normal;
        font-weight: 300;
        font-size: 17px;
        line-height: 20px;
        color: #807F80;
        margin: 0;
        margin-bottom: 30px;
    }
}
.texts {
    max-width: 481px;
}
h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 31px;
    color: #9FA09F;
    margin-top: 63px;
    margin-bottom: 36px;
}
.spans {
    span {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #807F80;
        display: block;
        margin-bottom: 13px;
    }
    a {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #807F80;
        display: inline-block;
        text-decoration: none;
    }
}
.maps {
    margin-top: 126px;
    display: flex;
    flex-direction: column;
    .map {
      margin-bottom: 51px;
    }
    img {
        max-width: 300px;
    }
    span {
        display: block;
        max-width: 300px;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: #0C0824;
        margin-top: 13px;
    }
}
@media screen and (max-width: 1100px) {
  .contact-page{
    padding: 0 40px;
  }
}

@media screen and (max-width: 900px) {
  .contact-page{
    flex-direction: column;
  }
  .maps {
    margin-top: 32px;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .maps {
    margin-top: 32px;
    flex-direction: column;
  }
}

@media screen and (max-width: 560px) {
  .contact-page{
      padding: 0 24px;
  }
  .maps img {
    max-width: 100%;
    width: 100%;
  }
  h3 {
    font-size: 28px;
    margin-top: 47px;
    line-height: 34px;
  }
}
</style>
