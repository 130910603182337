<template>
  <div class="page phoxbio">
    <div class="main container">
      <div class="img-p">
        <img src="../assets/img/icons/phoxgen.svg" alt="Phoxgen">
        <div class="p">
            <p>Pharmacologic barrier mainly comprised of components from the U.S. Food and Drug <br>
              Administration’s Generally Regarded as Safe (GRAS) list, that mimics the natural<br>
              interstitial fluid surrounding all cells and maintains healthy cell function.</p>
            <p>pHOXGEN is under development as a pharmaceutical product to inhibit SARS-CoV-2<br>
              and other airborne respiratory viruses. It is designed to inhibit viral entry, decrease <br>
              viral load and limit clinical progression of infection.</p>
            <p>
                Preliminary data from <i>in vitro</i>  testing shows pHOXGEN inhibits the growth of <br>
              SARS-CoV-2 variants in cells. These data are entirely consistent with our hypothesis <br>
              that pHOXGEN is mutation-agnostic. Tests looking at its mechanism of action <br>
              are continuing.
            </p>
            <p>Significant progress in the pre-clinical development programme to prove the effects<br>
				of pHOXGEN has been made. Pre-clinical tests have confirmed that pHOXGEN is safe.</p>
        </div>
    </div>
      <div class="tables">
          <div class="table">
              <div class="header">
                  <p>In vitro</p>
              </div>
              <div class="body">
                  <div class="el">
                      <b>Cytotoxicity studies</b>
                      <p>Q4 2020</p>
                  </div>
                  <div class="el">
                      <b>Anti-viral efficacy studies</b>
                      <p>Q3 2020 — Q2 2021</p>
                  </div>
                  <div class="el">
                      <b>Virucidal efficacy studies</b>
                      <p>Q4 2020</p>
                  </div>
                  <div class="el">
                      <b>Mechanism of action studies</b>
                      <p>Q4 2020 — Q4 2021</p>
                  </div>
                  <div class="el">
                      <b>Safety pharmacology studies</b>
                      <p>Q1 2021 — Q2 2021</p>
                  </div>
              </div>
          </div>
          <div class="table">
              <div class="header">
                  <p>In vivo</p>
              </div>
              <div class="body">
                  <div class="el">
                      <b>Tolerability studies</b>
                      <p>Q4 2020</p>
                  </div>
                  <div class="el">
                      <b>Acute safety studies</b>
                      <p>Q4 2020 — Q1 2021</p>
                  </div>
                  <div class="el">
                      <b>Long term safety studies</b>
                      <p>Q1 2021 — Q2 2021</p>
                  </div>
                  <div class="el">
                      <b>Anti-viral efficacy studies</b>
                      <p>Q1 2021 — TBC</p>
                  </div>
                  <div class="el">
                      <b>Safety pharmacology studies</b>
                      <p>Q1 2021 — Q2 2021</p>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!--<div class="q">-->
    <!--    <div class="container">-->
    <!--        <div class="qs">-->
    <!--            <div class="circle" style="background: #0C0824;">-->
    <!--                <p>Q4</p>-->
    <!--                <span>2020</span>-->
    <!--                <img class="m" src="../assets/img/icons/line-point.svg" alt="Point">-->
    <!--                <img class="s" src="../assets/img/icons/line-point-small.svg" alt="Point">-->
    <!--            </div>-->
    <!--            <div class="circle" style="background: #2C6BA4;">-->
    <!--                <p>Q1</p>-->
    <!--                <span>2021</span>-->
    <!--                <img class="m" src="../assets/img/icons/line-point.svg" alt="Point">-->
    <!--                <img class="s" src="../assets/img/icons/line-point-small.svg" alt="Point">-->
    <!--            </div>-->
    <!--            <div class="circle" style="background: #5F85B4;">-->
    <!--                <p>Q2</p>-->
    <!--                <img class="m" src="../assets/img/icons/line-point.svg" alt="Point">-->
    <!--                <img class="s" src="../assets/img/icons/line-point-small.svg" alt="Point">-->
    <!--            </div>-->
    <!--            <div class="circle fill" data-extrafillpercent="70" style="background: #8DA4C7;">-->
    <!--                <p>Q3</p>-->
    <!--                <img class="m" src="../assets/img/icons/line-point.svg" alt="Point">-->
    <!--                <img class="s" src="../assets/img/icons/line-point-small.svg" alt="Point">-->
    <!--            </div>-->
    <!--            <div class="circle"  style="background: #C1CBDD;">-->
    <!--                <p>Q4</p>-->
    <!--                <img class="m" src="../assets/img/icons/line-point.svg" alt="Point">-->
    <!--                <img class="s" src="../assets/img/icons/line-point-small.svg" alt="Point">-->
    <!--            </div>-->
    <!--            <div class="circle" style="background: #9E9F9E;">-->
    <!--                <p>Q1</p>-->
    <!--                <span>2022</span>-->
    <!--                <img class="m" src="../assets/img/icons/line-point.svg" alt="Point">-->
    <!--                <img class="s" src="../assets/img/icons/line-point-small.svg" alt="Point">-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
    <!--  <div class="lines">-->
    <!--    <div class="fill-line"></div>-->
    <!--  </div>-->
    <!--</div>-->
  </div>
</template>

<script>
export default {

  name: 'Phoxgen',

  mounted(){

    const fill_target = document.getElementsByClassName('fill')[0];
    const filler_line = document.getElementsByClassName('fill-line')[0];

    window.fill_target = fill_target;

    window.addEventListener('resize', function(){

      resize();
      // let fill_target_extrafill_pixels = fill_target.clientWidth * parseInt(fill_target.dataset.extrafillpercent) / 100;
      // filler_line.style.width = fill_target.offsetLeft + fill_target.clientWidth / 2 + 10 + fill_target_extrafill_pixels + 'px';

    });

    let resize = function(){

      filler_line.removeAttribute('style');

      if(window.innerWidth > 650){

        let fill_target_extrafill_pixels = fill_target.clientWidth * parseInt(fill_target.dataset.extrafillpercent) / 100;
        filler_line.style.width = fill_target.offsetLeft + fill_target.clientWidth / 2 + 10 + fill_target_extrafill_pixels + 'px';

      }else{

        let fill_target_extrafill_pixels = fill_target.clientHeight * parseInt(fill_target.dataset.extrafillpercent) / 100;
        filler_line.style.height = fill_target.offsetTop + fill_target.clientHeight / 2 + 10 + fill_target_extrafill_pixels + 'px';

      }

    }

    resize();

  },
}
</script>

<style scoped lang="scss">
.main {
    padding-top: 64px;
    padding-bottom: 70px;
    text-align: left;

    p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        color: #0C0824;
        margin: 0;
        text-align: left;
        padding-top: 2px;
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .img-p {
        display: flex;
        align-items: flex-start;
        

        img {
            margin-right: 46px;
        }

        .p {
            margin-bottom: 18px;
            p {
                margin-bottom: 30px;
            }
        }

    }

    b {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        color: #0C0824;
        margin-top: 0;
        display: block;
        margin-bottom: 16px;
        margin-top: 28px;
    }
    .tables {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2px;
    }
    .table {
        .header {
            height: 40px;
            background: #2C6BA4;
            p {
                font-style: italic;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                height: 100%;
                text-align: center;
                color: #FFFFFF;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .body {
            background: #CCD5E3;
            padding: 33px 20px;
            padding-bottom: 10px;
            .el {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
            }
            p {
                font-weight: 300;
                font-size: 18px;
                line-height: 24px;
                color: #0C0824;
                margin: 0;
            }
            b {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                color: #0C0824;
                margin: 0;
            }
        }
    }
}
.sub-vi {
    margin-top: 48px;
    text-align: left;
    b {
        margin: 0;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 24px;
    }
    
    p {
        margin: 0;
        font-size: 18px;
        line-height: 24px;
    }
}
.q {
    background-image: url(../assets/img/exp.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 32px 0;
    .qs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .circle {
            width: 152px;
            height: 152px;
            background: #0C0824;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            img {
                position: absolute;
                bottom: -47px;
                &.s {
                    display: none;
                }
            }
            span {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                color: #0C0824;
                position: absolute;
                bottom: -29px;
                left: 0;
            }
            p {
                font-weight: 500;
                font-size: 48px;
                line-height: 46px;
                text-align: center;
                color: #FFFFFF;
                margin: 0;
            }
        }
    }
}

.lines { // Desktop
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
  border-top: 4px dashed #0C0824;

  .fill-line{
    background-color: #000;
    height: 4px;
    position: absolute;
    left: 0;
    width: 100px;
    margin-top: -4px;
  }

}

@media screen and (max-width: 1200px) {
  .main.container {
    max-width: 100%;
    padding: 64px 40px;
    padding-bottom: 100px;
  }
  .main .table .body .el {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding-bottom: 14px;
  }
  .q {
      .container {
        max-width: 100%;
        margin: 0 40px;
      }
      .qs {
          .circle {
            width: 80px;
            height: 80px;
            p {
                font-size: 32px;
            }
            span {
                left: -17px;
            }
          }
      }
  }
}

@media screen and (max-width: 760px) {
    .main.container {
        padding: 30px 24px;
        padding-bottom: 100px;
    }
    .main .tables {
        grid-template-columns: 1fr;
        gap: 24px;
    }
    .main {
        .img-p {
            display: block;
            img {
                float: left;
                margin-right: 16px;
                margin-bottom: 8px;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .main {
        p {
            br {
                display: none;
            }
        }
    }
    .main.container {
        padding-bottom: 40px;
        b {
            margin-top: 8px;
        }
    }
    .q {
        position: relative;
        padding: 40px 0;
        .qs {
            flex-direction: column;
            align-items: flex-start;
            gap: 40px;
            .circle {
                margin-left: 21px;  
                img {
                    bottom: 31px;
                    left: -43px;
                    transform: rotate( 90deg );
                    display: none;
                    &.s {
                        display: block;
                        transform: none;
                    }
                }
                span {
                    left: unset;
                    right: -60px;
                    top: 35px;
                    font-size: 15px;
                    line-height: 24px;
                }
            }
        }
    }

  .lines { // Mobile
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 20px;
    bottom: 0;
    width: 4px;
    flex-direction: column;
    grid-template-rows: 3fr 1fr;
    border-right: 4px dashed #0C0824;

    .fill-line{
      background-color: #000;
      height: 100px;
      position: absolute;
      left: 0;
      width: 4px;
      margin-left: 4px;
    }

  }

}

.mb-40{
    margin-bottom: 40px;
}

</style>