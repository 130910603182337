<template>
	<div class="page">
		<div class="news-page">

			<div class="news" style="max-width: 800px;">

				<h2 style="margin-bottom: 30px;">
					Raphael Labs appoints Martin Walton as new CEO to support the advancement of its SARS-CoV-2 prophylaxis
					spray and pipeline development. </h2>


				<p>Galway and London, March 29th 2022 – Raphael Labs (RL) has announced the appointment of Martin Walton as
					the new Chief Executive Officer of the company and a member of its Board of Directors with immediate
					effect. Martin Walton is a seasoned executive, bringing extensive experience in the biotech and life
					sciences sectors.</p>

				<p>Martin Walton: “This is a great opportunity to join Raphael Labs as it prepares to roll-out pHOXWELL in
					Asia. The world’s focus remains on tackling infection rates of this deadly virus and stopping
					transmission. We are committed to helping achieve this as our pioneering prophylactic nasal spray
					prevents SARS-CoV-2. RL is an ambitious young biotech underpinned by deep science which has progressed
					from discovery to rollout in a 24-month period. There is great potential in its extensive R&D pipeline
					and I anticipate a period of transformative growth”.</p>

				<p>Walton’s appointment further strengthens the RL Board as the company executes expansion plans across the
					world.</p>

				<p>RL Chairman Professor Rakesh Uppal “Martin Walton has the strategic ability and leadership skills
					necessary to progress plans for working more closely with the UK and European regulators. pHOXWELL has
					global appeal as it prevents infection. Raphael Labs believes that prophylaxis will become an important
					pillar to control SARS-CoV-2 and all its variants and by limiting transmission allow economies to open
					up and life to get back to normal.</p>

				<p>Governments need a variety of tools to tackle pandemics and pHOXWELL has the potential to be a vital
					instrument in this. RL’s Board Members and I welcome the chance to work closely with Martin as we focus
					operations on our core mission to make pHOXWELL more available across the globe and develop further
					novel interventions.”</p>

				<p>Martin Walton has extensive experience in both the Biotech and Life Sciences sectors. He co-founded Arix
					Bioscience PLC and serves as CEO of Excalibur Medicines Limited. Martin serves on the Board of Directors
					of Liverpool Life</p>

				<p>Sciences Accelerator Partnership and is a Non-Executive Director of ReNeuron. Martin is a PE investor and
					VC activist and has advised other investment funds including start-up and spin-out investments, pre-IPO
					and IPO. He has raised over $1 billion in investment and co-investment capital and specialises in
					start-up, growth, turnaround and consolidation strategies. His corporate leadership is grounded in
					adherence to Governance and Oversight. Walton is committed to creating lasting value and long-term
					growth.</p>

				<p>Previously he enjoyed a 25-year career as a senior business leader in global investment banking and asset
					management, culminating as Vice Chair in charge of Wholesale and Commercial Banking for Europe and
					Asia-Pacific at Toronto Dominion Bank.</p>

				<p>Ends</p>

				<p>About Raphael Labs (RL)</p>
				<p>RL is a clinical stage European biotech with a significant UK operation. RL is the inventor of pHOXWELL,
					a prophylactic nasal spray clinically proven to prevent SARS-CoV-2. RL is committed to developing new
					interventions which target some of the world’s deadliest airborne respiratory viruses.</p>
				<p>Ends</p>

				<b>Media Contact:</b><br>
				Nicola Burdett: <a href="mailto:nb@raphlabs.com">nb@raphlabs.com</a><br>
				<b>Investor Contact:</b>
				Enda O’Connell: <a href="mailto:enda@raphlabs.com">enda@raphlabs.com</a><br>
				<a href="https://phoxbio.com">https://phoxbio.com</a><br>
				<a href="https://raphaellabs.com">https://raphaellabs.com</a>



			</div>

		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer-nav {
	position: absolute;
	width: 100%;
	bottom: 0;
}

.news-page {
	padding: 0 100px;
	text-align: left;
}

.news-item {
	display: block;
	width: 100%;
	margin-top: 25px;
}

h3 {
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 31px;
	color: #9fa09f;
	margin-top: 63px;
	margin-bottom: 36px;
}

.news {
	margin-bottom: 44px;

	span {
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		display: block;
		color: #9fa09f;
	}

	p {
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		line-height: 20px;
		color: #0c0824;
		margin-bottom: 8px;
	}

	a {
		font-weight: 300;
		font-size: 17px;
		line-height: 20px;
		text-decoration-line: underline;
		color: #0c0824;
	}
}

@media screen and (max-width: 1100px) {
	.news-page {
		padding: 0 40px;
	}
}

@media screen and (max-width: 560px) {
	.news-page {
		padding: 0 24px;
	}
	h3 {
		font-size: 28px;
		margin-top: 47px;
		line-height: 34px;
	}
}
</style>
