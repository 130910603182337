<template>
	<div class="page">
		<div class="news-page">

			<div class="news" style="max-width: 800px;">

				<h1 style="margin-bottom: 50px;">RAPHAEL LABS (RL) AND VINGROUP ANNOUNCE WORKING AGREEMENT TO BRING ANTI-SARS-CoV-2 NASAL SPRAY TO VIETNAM</h1>

				<p>RL has signed an MOU with Vingroup, the multi-sector conglomerate in Vietnam to advance talks on
					the manufacture and supply of pHOXWELL, a prophylactic nasal spray which prevents infection of
					SARS-CoV-2.</p>


				<img src="../../assets/news/Raphael_Labs_CM_Press_Release_2_11_21_header.jpg" alt="" style="max-width: 100%; margin-bottom: 50px;">

				<p><b>The MOU was signed in the presence of H.E. Pham Minh Chinh, Prime Minister of Vietnam,
					by Mr Nguyen Viet Quang, General Director of Vingroup (front right) and RL’s Mark
					Pawley (front left) on the side lines of COP26 in Edinburgh, Scotland.</b></p>

				<p>Under the MOU both parties agree to pursue the appropriate regulatory pathway for
					pHOXWELL, including a possible clinical trial, local large-scale manufacture and distribution
					in order to offer pHOXWELL’s benefits to the Vietnamese people. </p>

				<p><b>In a Ph2/3 clinical trial in India, pHOXWELL significantly reduced SARS-CoV-2 infection
					by 63% with tolerability comparable to placebo in a high-risk population of healthcare
					workers.</b></p>

				<p>RL Chairman Professor Rakesh Uppal said “It is a huge honour to be working with the
					prestigious Vingroup to make pHOXWELL available throughout Vietnam. Our prophylactic
					nasal spray offers an extra layer of protection as it complements vaccination and PPE. </p>

				<p>pHOXWELL is also suitable for those waiting to be vaccinated or who are unable. We believe
					pHOXWELL will become an effective tool to help bring the pandemic under control.” </p>

				<p>Mr Nguyen Viet Quang, Vice Chairman and CEO of Vingroup, said: “During this difficult time,
					Vingroup has made its best efforts to find and cooperate with our prestigious international
					partners in providing medicines, prophylactics and where possible to transfer technology to
					Vietnam. All these efforts are for an ultimate goal: to stop the pandemic. We will continue
					working closely with Raphael Labs in order to make the product available as soon as
					possible to the Vietnamese</p>

				<p>pHOXWELL is a combination of natural virucides and its scientific platform which is
					designed to be mutation-agnostic against SARS-CoV-2 and its variants. pHOXWELL is
					self-administered, offering 6-8 hours of protection with just two sprays per nostril, per
					application. Vaccination is essential but not 100% effective, and it is still possible to become
					infected by, and transmit, the virus that causes COVID-19. pHOXWELL adds an extra layer of
					protection as it prevents SARS-CoV-2 from infecting the nasal epithelia, the primary entry
					point into the body. pHOXWELL also targets other important airborne respiratory viruses.</p>


				<p>A SARS-CoV-2 prevention claim has been filed with the Indian regulator following
					pHOXWELL’s successful clinical trial. </p>

				<p>pHOXWELL is low cost, can be manufactured at large scale and easy to transport due to
					its room temperature storage. It is suitable for geographies where vaccination levels remain
					inadequate and PPE scarce.</p>

				<p>RL is a cutting-edge BioTechnology firm founded in Ireland with a significant UK operation,
					developing breakthrough science to inhibit SARS-CoV-2 and other important airborne
					respiratory viruses</p>

				<p><b>About Vingroup</b></p>
				<p>As the largest private conglomerate in Vietnam, Vingroup is currently doing business in three
					core sectors namely technology, industry and services. In all sectors it has participated in,
					Vingroup is always a pioneer that leads market trends and creates world-class products and
					services of Vietnam. Find out more at: https://www.vingroup.net/en</p>

			</div>

		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer-nav {
	position: absolute;
	width: 100%;
	bottom: 0;
}

.news-page {
	padding: 0 100px;
	text-align: left;
}

.news-item {
	display: block;
	width: 100%;
	margin-top: 25px;
}

h3 {
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 31px;
	color: #9fa09f;
	margin-top: 63px;
	margin-bottom: 36px;
}

.news {
	margin-bottom: 44px;

	span {
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		display: block;
		color: #9fa09f;
	}

	p {
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		line-height: 20px;
		color: #0c0824;
		margin-bottom: 8px;
	}

	a {
		font-weight: 300;
		font-size: 17px;
		line-height: 20px;
		text-decoration-line: underline;
		color: #0c0824;
	}
}

@media screen and (max-width: 1100px) {
	.news-page {
		padding: 0 40px;
	}
}

@media screen and (max-width: 560px) {
	.news-page {
		padding: 0 24px;
	}
	h3 {
		font-size: 28px;
		margin-top: 47px;
		line-height: 34px;
	}
}
</style>
