<template>
	<div class="page phoxbio">
		<div class="main container">
			<div class="img-p">
				<div class="p">
          <h2>Study Design and Results</h2>

          <!--Pharmacologic barrier mainly comprised of components from the U.S. Food and Drug-->

          <p>The study was a double-blind, randomized, placebo-controlled study to assess the<br>
            efficacy and safety of pHOXWELL nasal spray in the prevention of SARS-CoV-2 infection<br>
            in high-risk healthcare professionals in India. The trial was carried out during the<br>
            peak surge of the highly infectious Delta variant in India in April to July 2021. <br>
            The study was designed following the guidance of and approval by the appropriate regulator.<br>
            The primary endpoint was the percentage of subjects who test positive for SARS-CoV-2 on IgGS <br>
            (spike protein specific) testing over the 45 days of the study. Secondary endpoints included<br>
            efficacy, safety and tolerability measures.</p>

          <p>The primary endpoint showed that 13.1% of subjects were IgGS positive in the pHOXWELL arm<br>
            versus 34.5% in the placebo arm (p= &lt;0.0001). This result shows that pHOXWELL has a <br>
            significant prophylactic effect versus SARS-CoV-2 infection when compared to placebo.<br>
            These highly statistically significant results were consistent across the two sites<br>
            which recruited subjects (Site 1 17.4% vs 54.6%, p= &lt;0.0001; Site 2 11.1% vs 23.9%,<br>
            p=0.0015) and sex (male 13.6% vs 36.6%, p= &lt;0.0001; female 12.1% vs 31.2%, p=0.0013).</p>

          <p>The secondary endpoint looking at subjects experiencing clinical symptoms, also shows<br>
            significant results in favour of pHOXWELL, with only 17.6% of subjects who succumbed to<br>
            infection in the pHOXWELL arm having clinical symptoms versus 34.6% in the placebo arm<br>
            (p= &lt;0.0001). These results were consistent across sex (male 15.3% vs 31.8%, p= 0.0001;<br>
            female 21.7% vs 39%, p= 0.0048) and age groups (18-35 26.5% vs 43.6%, p= 0.0091; 36-65 19.4%<br>
            vs 36.9% p= 0.0018; 65+ 6.9% vs 33.3%, p= 0.0407).</p>

          <p>User acceptability end points were positive, with an overall positive experience maintained<br>
            across the study. pHOXWELL also exhibited an excellent safety profile. 648 subjects completed<br>
            the study. The mean age was 40.8 years. 63.3% of subjects were male and 36.7% female.<br>
            Subjects were over 18, unvaccinated, demonstrated not to be infected with SARS-CoV-2 at the time<br>
            of entry (RT-PCR), and not to have had any previous infections (IgG Spike protein negative).<br>
            They were treated three times a day with active or placebo prior to any possible exposure<br>
            situation to SARS-CoV-2 over 45 days. Subjects were regularly tested by RT-PCR for SARS-CoV-2<br>
            infection, 556 had IgGS testing at the last visit and potential symptoms and adverse events were<br>
            recorded. <br>
            A technical issue during the study caused RT-PCR testing not to work for the infection end<br>
            point for all subjects, hence the use of IgGS testing.</p>

          <h2>Company Seeking To Deliver Benefits to Society</h2>

          <p>pHOXBIO and its parent company, Raphael Labs, will now initiate regulatory filings to support a<br>
            SARS-CoV-2 prevention claim with the appropriate regulator, based on the clinical trial data.<br>
            This will allow for production and distribution of pHOXWELL in India initially, with further<br>
            territories to follow as a prophylaxis against SARS-CoV-2 infections.</p>

          <p>Company Chairman, Professor Rakesh Uppal, Professor of Cardiovascular Surgery at Queen Mary<br>
            University of London and Director of Barts Life Sciences, says, “there is a pressing need globally<br>
            for a prophylactic nasal spray to help prevent infection in areas where vaccination rollout remains<br>
            inadequate in the face of the tragic human toll of this pandemic. We are confident that our<br>
            anti-COVID-19 nasal spray, pHOXWELL, will become a vital part of the global armoury to provide<br>
            an extra defensive shield to tackle the pandemic, and we welcome forming partnerships with governments,<br>
            NGOs and manufacturers to deliver the preventative benefits of pHOXWELL”.</p>

          <p>pHOXWELL is low cost to manufacture, stable at room temperature and easy to transport. We anticipate its<br>
            use will be applicable to many populations where vaccination rates remain low and PPE is scarce,<br>
            particularly for frontline healthcare workers. pHOXBIO is seeking to evaluate opportunities that can<br>
            help accelerate the delivery of its products’ benefits to society. Interested parties can contact<br>
            the company at <a href="https://phoxbio.com/#/contact.">https://phoxbio.com/#/contact.</a></p>

        </div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
	.main {
		padding-top: 64px;
		text-align: left;
		padding-bottom: 70px;
        p {
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 24px;
            color: #0C0824;
            margin: 0;
            text-align: left;
            padding-top: 2px;
			margin-bottom: 49px;
        }

		.img-p {
			display: flex;
			align-items: flex-start;


			img {
				margin-right: 46px;
			}
		}

		b {
			font-style: normal;
			font-weight: 500;
			font-size: 17px;
			line-height: 24px;
			color: #0C0824;
			margin-top: 50px;
			display: block;
			margin-bottom: 16px;
		}

		.tables {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 2px;
			overflow: hidden;
		}

		.table {
			.header {
				height: 40px;
				background: #2C6BA4;

				p {
					font-style: italic;
					font-weight: 500;
					font-size: 20px;
					line-height: 24px;
					height: 100%;
					text-align: center;
					color: #FFFFFF;
					margin: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			.body {
				background: #CCD5E3;
				padding: 33px 20px;
				padding-bottom: 10px;
				height: 100%;

				.el {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 15px;
				}

				p {
					font-weight: 300;
					font-size: 18px;
					line-height: 24px;
					color: #0C0824;
					margin: 0;
				}

				b {
					font-weight: 500;
					font-size: 18px;
					line-height: 24px;
					color: #0C0824;
					margin: 0;
				}
			}
		}
	}

	.sub-vi {
		margin-top: 48px;
		text-align: left;
		b {
			margin: 0;
			margin-bottom: 20px;
			font-size: 18px;
			line-height: 24px;
		}
		
		p {
			margin: 0;
			font-size: 18px;
			line-height: 24px;
		}
	}

	.q {
		background-image: url(../assets/img/basil.jpeg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 32px 0;

		.qs {
			display: flex;
			align-items: center;

			.circle {
				width: 152px;
				height: 152px;
				background: #0C0824;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				margin-right: 51px;

				img {
					position: absolute;
					bottom: -47px;

					&.s {
						display: none;
					}
				}

				span {
					font-weight: 500;
					font-size: 18px;
					line-height: 24px;
					color: #0C0824;
					position: absolute;
					bottom: -29px;
					left: 0;
				}

				p {
					font-weight: 500;
					font-size: 48px;
					line-height: 46px;
					text-align: center;
					color: #FFFFFF;
					margin: 0;
				}
			}
		}
	}

	.lines {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 32px;

		.left {
			height: 8px;
			background: #0C0824;
			width: 100%;
		}

		.right {
			width: 100%;
			border-top: 4px dashed #0C0824;
			height: 4px;
			margin-top: 4px;
		}
	}


	@media screen and (max-width: 1200px) {
		.main.container {
			max-width: 100%;
			padding: 64px 40px;
			padding-bottom: 100px;
		}
		.main .table .body .el {
			flex-direction: column;
			align-items: flex-start;
			gap: 4px;
			padding-bottom: 14px;
		}
		.q {
			.container {
				max-width: 100%;
				margin: 0 40px;
			}

			.qs {
				.circle {
					width: 80px;
					height: 80px;

					p {
						font-size: 32px;
					}

					span {
						left: -17px;
					}
				}
			}
		}
	}

	@media screen and (max-width: 760px) {
		.main.container {
			padding: 30px 24px;
			padding-bottom: 100px;
		}
		.main .tables {
			grid-template-columns: 1fr;
			gap: 24px;

			.body {
				height: auto;
			}
		}
		.main {
			.img-p {
				display: block;

				img {
					float: left;
					margin-right: 16px;
					margin-bottom: 8px;
				}
			}
		}
	}

	@media screen and (max-width: 650px) {
		.main {
			p {
				br {
					display: none;
				}
			}
		}
		.main.container {
			padding-bottom: 40px;

			b {
				margin-top: 8px;
			}
		}
		.q {
			position: relative;
			padding: 40px 0;
			background-position: right;

			.qs {
				flex-direction: column;
				align-items: flex-start;

				.circle {
					margin-left: 21px;
					margin-bottom: 40px;

					img {
						bottom: 31px;
						left: -43px;
						transform: rotate(90deg);
						display: none;

						&.s {
							display: block;
							transform: none;
						}
					}

					span {
						left: unset;
						right: -60px;
						top: 35px;
						font-size: 15px;
						line-height: 24px;
						color: white;
					}
				}
			}
		}
		.lines {
			margin-top: 0;
			position: absolute;
			top: 0;
			left: 24px;
			bottom: 0;
			width: 4px;
			flex-direction: column;
			grid-template-rows: 3fr 1fr;

			.left {
				height: 100%;
				width: 4px;
				display: block;
			}

			.right {
				width: 4px;
				border-right: 4px dashed #0C0824;
				margin-left: -4px;
				border-top: 0;
				height: 100%;
			}
		}
	}

	.mb-40{
		margin-bottom: 40px;
	}

</style>