<template>
  <div class="page focus">
    <div class="main">
      <h5>Our Focus</h5>
      <p>pHOXBIO is committed to developing medicines and wellness products using scientific breakthroughs to protect society 
        from the spread of airborne respiratory viruses that 
        cause some of the world’s deadliest communicable diseases.  We believe our novel solutions have the potential to
         help control the largest public health 
      issue of our generation and improve preparedness for future challenges that SARS-CoV-2 and its mutations may bring.</p>
      <p>We are confident our platforms will become a vital part of the global health standard of care to control airborne viruses, 
        enabling people around the world to live their lives more normally and with less fear.</p>
    </div>
    <div class="main image">

    </div>
  </div>
</template>

<script>
export default {
  name: 'Focus',
}
</script>

<style scoped lang="scss">
  .phox {
    margin: 0 auto;
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 700px;
  }
  .main {
    height: unset;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0 100px;
    padding-top: 53px;
    padding-bottom: 55px;
    position: relative;
    &.image {
      height: 528px;
      background-image: url(../assets/img/focus.jpg);
    }
    h5 {
      font-weight: 300;
      font-size: 59px;
      line-height: 56px;
      color: #0C0824;
      margin: 0;
      max-width: 565px;
      text-align: left;
      margin-bottom: 42px;
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 17.5px;
      line-height: 24px;
      color: #0C0824;
      max-width: 616px;
      text-align: left;
      margin-bottom: 21px;
    }
  }
  .raphael {
    padding: 98px 0;
    img.raph {
      margin-bottom: 50px;
    }
    p{
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      color: #110E29;
      max-width: 707px;
      text-align: center;
      margin: 0 auto;
    }
  }

@media screen and (max-width: 768px) {
  .main {
    padding: 0 40px;
    padding-top: 63px;
  }
  .raphael {
    padding: 60px 24px;
    p {
      max-width: 600px;
    }
  }
  .phox {
    margin-top: 59px;
  }
}

@media screen and (max-width: 670px) {
  .phox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 33px;
    justify-items: center;
  }
}

@media screen and (max-width: 560px) {
  .main {
    padding: 0 24px;
    padding-top: 43px;
    padding-bottom: 43px;
    height: auto;
    min-height: 528px;
    background-position: -1100px;
    &.image {
      min-height: 200px;
      height: 268px;
      background-position: -274px;
    }
  }
  .raphael {
    img.raph {
      width: 205px;
      margin-bottom: 40px;
    }
    p {
      font-size: 20px;
      line-height: 150%;
    }
  }
  .phox {
    margin-top: 37px;
  }
}

</style>
