<template>

  <nav class="container-xxl flex-wrap flex-md-nowrap main-nav" aria-label="Main navigation">
    <a class="navbar-brand p-0 me-2" href="/" aria-label="pHoxbio">
      <img src="../assets/img/logo.svg" alt="pHOXBIO">
    </a>

    <ul class="navbar-nav flex-row flex-wrap ms-md-auto">
      <li>
        <a class="nav-link">About<img src="../assets/img/icons/arr-down-min.svg" alt="arr-down"></a>
        <div class="sub-a-content">
          <div class="outer">
            <div class="inner"></div>
          </div>
          <router-link class="nav-link" to="our-focus">Our focus</router-link> 
          <router-link class="nav-link" to="team">Team</router-link> 
        </div>
      </li>
      <li>
        <a class="nav-link">Disease<img src="../assets/img/icons/arr-down-min.svg" alt="arr-down"></a>
        <div class="sub-a-content">
          <div class="outer">
            <div class="inner"></div>
          </div>
          <router-link class="nav-link" to="burden-of-arps">Burden of Arps</router-link> 
          <router-link class="nav-link" to="substantial-gaps">Substantial Gaps</router-link> 
        </div>
      </li>
      <li>
        <a class="nav-link">Our Solutions<img src="../assets/img/icons/arr-down-min.svg" alt="arr-down"></a>
        <div class="sub-a-content">
          <div class="outer">
            <div class="inner"></div>
          </div>
          <router-link class="nav-link" to="solution-breakthrough">Solution Breakthrough</router-link>
          <router-link class="nav-link" to="phoxwell">pHOXWELL</router-link>
          <router-link class="nav-link" to="phoxgen">pHOXGEN</router-link>
        </div>
      </li>
      <li>
        <router-link class="nav-link" to="news">News</router-link>
      </li>
      <li>
        <router-link class="nav-link" to="contact">Contact</router-link> 
      </li>
    </ul>
    <button class="menu-toggle tablet" 
      v-on:click="toggleTabletNav"
      v-bind:class="{active: showTabletActive}">
      <img v-if="!showTabletActive" src="../assets/img/icons/menu-lines.svg" alt="Menu toggle">
      <img v-if="showTabletActive" class="close-icon" src="../assets/img/icons/close-menu.svg" alt="Menu toggle">
    </button>

    <button class="menu-toggle mobile" 
      v-on:click="toggleMobileNav"
      v-bind:class="{active: showMobileActive}">
      <img v-if="!showMobileActive" src="../assets/img/icons/menu-lines.svg" alt="Menu toggle">
      <img v-if="showMobileActive" class="close-icon" src="../assets/img/icons/close-menu.svg" alt="Menu toggle">
    </button>

  </nav>
  <nav class="container-xxl flex-wrap flex-md-nowrap tablet-nav" v-bind:class="{show: showTabletActive}">
    <ul class="navbar-nav flex-row flex-wrap ms-md-auto">
      <li>
        <a class="nav-link">About<img src="../assets/img/icons/arr-down-min.svg" alt="arr-down"></a>
        <div class="sub-a-content">
          <div class="outer">
            <div class="inner"></div>
          </div>
          <router-link class="nav-link" to="our-focus">Our focus</router-link> 
          <router-link class="nav-link" to="team">Team</router-link> 
        </div>
      </li>
      <li>
        <a class="nav-link">Disease<img src="../assets/img/icons/arr-down-min.svg" alt="arr-down"></a>
        <div class="sub-a-content">
          <div class="outer">
            <div class="inner"></div>
          </div>
          <router-link class="nav-link" to="burden-of-arps">Burden of Arps</router-link> 
          <router-link class="nav-link" to="substantial-gaps">Substantial Gaps</router-link> 
        </div>
      </li>
      <li>
        <a class="nav-link">Our Solutions<img src="../assets/img/icons/arr-down-min.svg" alt="arr-down"></a>
        <div class="sub-a-content">
          <div class="outer">
            <div class="inner"></div>
          </div>
          <router-link class="nav-link" to="solution-breakthrough">Solution Breakthrough</router-link>
          <router-link class="nav-link" to="phoxgen">pHOXGEN</router-link> 
          <router-link class="nav-link" to="phoxwell">pHOXWELL</router-link> 
        </div>
      </li>
      <li>
        <router-link class="nav-link" to="news">News</router-link> 
      </li>
      <li>
        <router-link class="nav-link" to="contact">Contact</router-link> 
      </li>
    </ul>
  </nav>

  <nav class="container-xxl flex-wrap flex-md-nowrap mobile-nav" v-bind:class="{show: showMobileActive}">
    <ul class="navbar-nav flex-row flex-wrap ms-md-auto">
      <li>
        <a v-on:click="toggleSubMenu('about')" class="nav-link w" >About<img src="../assets/img/icons/arr-down-min.svg" alt="arr-down"></a>
        <div class="sub-ul about active">
          <router-link v-on:click="closeMobileNav()" class="nav-link" to="our-focus">Our focus</router-link> 
          <router-link v-on:click="closeMobileNav()" class="nav-link" to="team">Team</router-link> 
        </div>
      </li>
      <li>
        <a v-on:click="toggleSubMenu('disease')" class="nav-link w" >Disease<img src="../assets/img/icons/arr-down-min.svg" alt="arr-down"></a>
        <div class="sub-ul disease active">
          <router-link v-on:click="closeMobileNav()" class="nav-link" to="burden-of-arps">Burden of Arps</router-link> 
          <router-link v-on:click="closeMobileNav()" class="nav-link" to="substantial-gaps">Substantial Gaps</router-link> 
        </div>
      </li>
      <li>
        <a v-on:click="toggleSubMenu('solutions')" class="nav-link w">Our Solutions<img src="../assets/img/icons/arr-down-min.svg" alt="arr-down"></a>
        <div class="sub-ul solutions active">
          <router-link v-on:click="closeMobileNav()" class="nav-link" to="solution-breakthrough">Solution Breakthrough</router-link>
          <router-link v-on:click="closeMobileNav()" class="nav-link" to="phoxgen">pHOXGEN</router-link> 
          <router-link v-on:click="closeMobileNav()" class="nav-link" to="phoxwell">pHOXWELL</router-link> 
        </div>
      </li>
      <li>
        <router-link v-on:click="closeMobileNav()" class="nav-link" to="news">News</router-link> 
      </li>
      <li>
        <router-link v-on:click="closeMobileNav()" class="nav-link" to="contact">Contact</router-link> 
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
    data() {
      return {
          showTabletActive: false,
          showMobileActive: false
      }
    },
    methods: {
        toggleTabletNav(){
            this.showTabletActive = !this.showTabletActive
        },

        toggleMobileNav(){
            this.showMobileActive = !this.showMobileActive
            document.querySelector("body").classList.toggle('no-overflow');
        },

        closeMobileNav() {
            this.showMobileActive = false;
            document.querySelector(".sub-ul").classList.remove('active');
            document.querySelector("body").classList.remove('no-overflow');
        },

        toggleSubMenu(){
            // document.querySelector(".sub-ul").classList.remove('active');
            // document.querySelector("." + menu).classList.toggle('active');
        }
    }
}
</script>

<style scoped lang="scss">
.navbar-brand {
  line-height: 0;
}
.main-nav {
  z-index: 99999999;
  position: relative;
}
nav {
  display: flex;
  padding: 0 100px;
  height: 78px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 6px 10px -2px rgba(13, 31, 64, 0.05);
  &.mobile-nav {
    display: none;
    padding: 0;
    .nav-link.w {
      margin-left: 13px;
    }
    li {
      flex-direction: column;
      align-items: center;
    }
    .sub-ul {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 21px;
      // height: 0;
      // opacity: 0;
      // margin: 0;
      transition: all 0.2s ease-in-out;
      &.active {
        height: auto;
        opacity: 1;
        margin-top: 21px;
      }
      a {
        font-size: 16px;
        line-height: 19px;
        font-weight: 300;
        margin-bottom: 12px;
      }
    }
    &.show {
      display: flex;
      top: 0;
      bottom: 0;
      height: 100%;
      position: fixed;
      background: white;
      z-index: 999;
      width: 100%;
      ul {
        flex-direction: column;
        height: auto;
        width: 100%;
        li {
          margin-bottom: 17px;
        }
        a {
          img {
            // position: absolute;
            // right: -18px;
          }
        }
      }
    }
  }
  &.tablet-nav {
    position: absolute;
    top: 78px;
    z-index: 9;
    background: white;
    left: 0;
    right: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    border-top: 1px solid #DCDEDC;
    &.show {
      height: 78px;
      opacity: 1;
      pointer-events: all;
    }
  }

  .menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    &.mobile {
      display: none;
    }
    .close-icon {
      height: 34px;
      position: relative;
      right: -7px;
    }
  }
}
.navbar-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
    height: 40px;
    li {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;
      height: 100%;
      margin-left: 39px;
      &:hover {
        .sub-a-content {
            display: flex;
        }
      }
      .nav-link {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        text-decoration: none;
        color: #0C0824;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          margin-left: 6px;
        }
      }
    }
    .sub-a-content {
      display: none;
      position: absolute;
      background-color: #ffffff;
      min-width: 160px;
      z-index: 9999;
      margin-top: 39px;
      top: 2px;
      align-items: baseline;
      justify-content: center;
      border-radius: 10px;
      box-shadow: 0px 3px 50px rgb(4 15 34 / 13%);
      padding: 21px 24px;
      padding-top: 11px;
      flex-direction: column;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      .nav-link {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 13px;
        color: #0D1F40;
        margin-top: 14px;
      }
      .outer {
        position: absolute;
        top: -74px;
        left: 0;
        right: 0;
        width: 70px;
        height: 140px;
        overflow: hidden;
        margin: 0 auto;
        transform: rotate(
        -90deg
        ) scale(0.2);
      }
      .inner {
        transform: rotate(
        45deg
        );
        background-color: white;
        width: 100px;
        height: 100px;
        top: 20px;
        left: -50px;
        position: relative;
        border-radius: 20px;
      }
    }
}

@media screen and (max-width: 1100px) {
  nav {
    padding: 0 40px;
  }

  nav.main-nav {
    .navbar-nav  {
      display: none;
    }
    
    .menu-toggle.tablet {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .tablet-nav {
    display: none;
  }
  .main-nav .menu-toggle {
    padding: 0;
    &.tablet {
      display: none!important;
    }
    &.mobile {
      display: block!important;
    }
  }
}

@media screen and (max-width: 560px) {
  nav {
    padding: 0 24px;
    .navbar-brand {
      img {
        width: 100px;
      }
    }
  }
  .navbar-nav {
    li {
      margin-left: 0;
    }
  }
}
</style>
