<template>
	<div class="page">
		<div class="imgs arps">
			<h5>Substantial gaps in current standards of care to control ARVs</h5>
			<div class="row">
				<img src="../assets/img/icons/tr1.svg" alt=""> <span>Vaccines to prevent disease</span>
				<ul>
					<li><p>None for rhinoviruses, coronaviruses<br> (except SARS-CoV-2)</p></li>
					<li><p>Modest efﬁcacy against inﬂuenza</p></li>
					<li><p>Supply challenges in warm climates, rural areas</p></li>
					<li><p>Poor access in developing countries</p></li>
					<li><p>Hesitancy to use <sup>Ref 1, Ref 2, Ref 3</sup></p></li>
					<li><p>Do not prevent viral inﬁltration</p></li>
				</ul>
				<p class="al">Insufficient alone</p>
			</div>
			<div class="row">
				<img src="../assets/img/icons/tr2.svg" alt=""> <span>PPE to prevent transmission</span>
				<ul>
					<li><p>Insufficiently effective</p></li>
					<li><p>Failure to use, incorrect use and malfunction</p></li>
					<li><p>Poor availability in many settings and countries</p></li>
				</ul>
				<p class="al">Inadequate alone</p>
			</div>
			<div class="row">
				<img src="../assets/img/icons/tr4.svg" alt=""> <span>Prophylactic medicines</span>
				<ul>
					<li><p>None identiﬁed for coronaviruses and rhinoviruses</p></li>
					<li><p>Routine inﬂuenza prophylaxis not recommended</p></li>
				</ul>
				<p class="al">Inadequate</p>
			</div>
			<div class="row">
				<img src="../assets/img/icons/tr3.svg" alt=""> <span>Good hygiene</span>
				<ul>
					<li><p>Poor understanding of “good” measures</p></li>
					<li><p>Inconvenient access to routine hygiene aids</p></li>
				</ul>
				<p class="al">Unreliable</p>
			</div>
			<div class="row">
				<img src="../assets/img/icons/tr5.svg" alt=""> <span>Social distancing and isolation</span>
				<ul>
					<li><p>Impractical in many situations</p></li>
					<li><p>Low acceptance, especially over time</p></li>
					<li><p>Not suitable over the long term</p></li>
				</ul>
				<p class="al">Unsustainable</p>
			</div>
		</div>
		<div class="img-dark pandemics" style="padding: 60px 100px;">
			<h3>Vaccines alone are not enough to control pandemics </h3>
			<div class="group">
				<div class="supply">
					<h5>Major Supply Challenges</h5>
					<p>Constrained manufacturing capacity slows ability to vaccinate large populations</p>
					<p>”Vaccine nationalism” creating competition between countries for supplies</p>
					<p>Vaccine supplies are often slow to reach low and middle-income countries compared to high-income
						countries</p>
					<p>Cold-chain shipping requirements present signiﬁcant logistical challenges in many countries and
						rural areas</p>
				</div>
				<div class="ramo-up">
					<h5>Vaccine Hesitancy</h5>
					<div class="line" style="padding-top: 0;">
						<h2>Top 10</h2>
						<p>“Vaccine Hesitancy” identiﬁed by WHO as a top global health threat <sup>Ref 4</sup></p>
					</div>
					<div class="line">
						<h2>70-85%</h2>
						<p>vaccination globally is required to achieve herd immunity according to some experts <sup>Ref
							5</sup></p>
					</div>
					<div class="line">
						<h2>22% - 42.4%</h2>
						<p>of US public hesitant to get Covid-19 vaccine <sup>Ref 6</sup></p>
					</div>
					<div class="line">
						<h2>>30%</h2>
						<p>not receptive to vaccination in Canada, Germany, Sweden, Nigeria, France, Poland, Russia
							<sup>Ref 7</sup></p>
					</div>
					<div class="line">
						<h2>15%</h2>
						<p>of UK healthcare workers are hesitant about COVID-19 vaccines <sup>Ref 8</sup></p>
					</div>
				</div>

				<div class="optinons">
					<h5>Emerging Viral Threats</h5>
					<ul>
						<li><p>~1 in 5 newly emerging or re-emerging pathogens are respiratory viruses <sup>Ref 9</sup>
						</p></li>
						<li><p>Discovery of Delta and Omicron Variants, with global reach and impact.</p></li>
						<li><p>Vaccination doesn’t always prevent infection. Over time vaccine protection wanes. <sup>Ref 20 25</sup></p></li>
					</ul>

					<h5>High Economic Impact</h5>
					<ul>
						<li><p>Being infected, having symptoms has high economic cost. Days off work, childcare cover.
							Continuing impact on healthcare systems globally. Hospital admissions and visits to
							doctor.</p></li>
					</ul>

					<h5>Widening vaccine inequity</h5>
					<ul>
						<li><p>2nd vaccination - Europe 65.22% / Africa 15.10% <sup>ref26</sup></p></li>
					</ul>
				</div>

				<div class="optinons">
					<h5>Booster Impact</h5>
					<p>Push for 3rd and 4th round of vaccines in some countries.</p>
					<ul style="margin-bottom: 0">
						<li><p>Booster Vaccines administered per 100 people <sup>Ref 23</sup></p></li>
					</ul>

					<ul style="margin-left: 22px; margin-top: -11px;">
						<li style="margin-bottom: 6px;">
							<p>Italy 64</p>
						</li>
						<li style="margin-bottom: 6px;">
							<p>United Kingdom 56.5</p>
						</li>
						<li style="margin-bottom: 6px;">
							<p>Brazil 33.2</p>
						</li>
						<li style="margin-bottom: 6px;">
							<p>United States 29.0</p>
						</li>
						<li style="margin-bottom: 6px;">
							<p>Worldwide Average 18.4</p>
						</li>
						<li style="margin-bottom: 6px;">
							<p>India 1.4</p>
						</li>
					</ul>

					<ul>
						<li><p>More funding for pandemic preparedness to prevent current advances from being lost and
							future pandemics manageable <sup>Ref 19 Ref 23</sup></p></li>
					</ul>

					<p>Mutations of existing virus strains may actively impact vaccine efﬁcacy</p>

					<ul>
						<li><p>COVID-19 vaccines may not cover the new viral strains <sup>Ref 10, Ref 11</sup></p></li>
					</ul>
				</div>

			</div>
		</div>
		<div class="references">
			<h3>References</h3>

			<p>
				1. Global Health Estimates 2016. Deaths by Cause, Age, Sex by Country and by Region. 2000-2016. World Health Organization 2018.<br>
				2. World Health Organization. Global Health Estimates. Top 10 causes of death. Accessed 9 December 2020.<br>
				4. J Glob Health. 2019 Dec; 9(2): 020421.<br>3. Influenza (seasonal) fact sheet. WHO. Accessed 1 June 2020. <br>
				6. Trish M. Universal Influenza Vaccination Among Healthcare Personnel: Yes We Should. Open Forum Infectious Diseases 2019; 6(4).<br>5. World Health Organisation, Seasonal Influenza and Communicable Diseases <br>
				8. Human Rhinoviruses. Clinical Microbiology Reviews. 26 (1): 135–62. 2013.<br>7. Common cold overview. WebMD. Accessed 1 June 2020. <br>
				10. How COVID-19 is changing the world: a statistical perspective. Committee for the Coordination of Statistical Activities (CCSA). Published May 2020.<br>9.. World O Meter Coronavirus Statistics
				<a href="https://www.worldometers.info/coronavirus/">https://www.worldometers.info/coronavirus/</a> <br>
				11. International Labour Organization. ILO Monitor: COVID-19 and the world of work. Seventh edition Updated estimates and analysis. 25 Jan 2021.<br>
				13. UNWTO World Tourism Barometer May 2020. United Nations World Tourism Organization. Published 7 May 2020.<br>12. OECD Economic Outlook — June 2020 Update. OECD. <br>
				15. Human coronavirus types. Centers for Disease Control. Published 15 February 2020.<br>14. COVID-19 Educational Disruption and Response. UNESCO. Accessed 1 June 2020. <br>
				17.. Health Insurance Status and Risk Factors for Poor Outcomes With COVID-19 Among U.S. Health Care Workers. Annals Int Med. Published 28 April 2020. Annals Int Med. (Himmelstein) 173;5. Published 1 Sept 2020.<br>16. Health Aff. (Millwood) 35, 2124–2132 (2016). <br>
				18. Omicron largely evades Immunity from Past Infection, Imperial College London <a href="https://www.imperial.ac.uk/news/232698/omicron-largely-evades-immunity-from-past/#:~:text=The%20new%20report%20(Report%2049,be%20as%20low%20as%2019%25">https://www.imperial.ac.uk/news/232698/omicron-largely-evades-immunity-from-past</a>.<br>
				19. Sarah Gilbert: Next pandemic could be more lethal than Covid <a href="https://www.bbc.co.uk/news/health-59542211">https://www.bbc.co.uk/news/health-59542211</a><br>
				21. Office for National Statistics, Booster Impact <a href="https://www.ons.gov.uk/peoplepopulationandcommunity/healthandsocialcare/conditionsanddiseases/articles/coronaviruscovid19latestinsights/vaccines">https://www.ons.gov.uk/peoplepopulationandcommunity/healthandsocialcare/conditionsanddiseases/articles/coronaviruscovid19latestinsights/vaccines</a><br>
				20. New England Journal of Medicine, <a href="https://www.nejm.org/doi/full/10.1056/NEJMoa2119451">https://www.nejm.org/doi/full/10.1056/NEJMoa2119451</a><br>
				22.Manchester Evening News, <a href="https://www.manchestereveningnews.co.uk/news/uk-news/chris-whitty-explains-fears-over-22297799">https://www.manchestereveningnews.co.uk/news/uk-news/chris-whitty-explains-fears-over-22297799</a><br>
				23. Chief Medical. Officer Chris Whitty Official Twitter: <a href="https://twitter.com/cmo_england/status/1370340138769866755?lang=en">https://twitter.com/cmo_england/status/1370340138769866755?lang=en</a><br>
				24. Our World in Data Covid Vaccine Booster Dose Status per capita <a href="https://www.theguardian.com/commentisfree/2022/mar/28/uk-cant-rely-on-boosters-new-wave-covid">https://www.theguardian.com/commentisfree/2022/mar/28/uk-cant-rely-on-boosters-new-wave-covid</a><br>
				25. Guardian <a href="https://www.theguardian.com/commentisfree/2022/mar/28/uk-cant-rely-on-boosters-new-wave-covid">https://www.theguardian.com/commentisfree/2022/mar/28/uk-cant-rely-on-boosters-new-wave-covid</a><br>
				26. Our World in Data Covid Statistics
			</p>

		</div>
	</div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.imgs {
	margin: 0 100px;
	margin-top: 2px;
	margin-bottom: 15px;

	&.arps {
		margin: 60px 100px;

		img {
			margin: 0;
			padding: 0;
		}

		h5 {
			margin: 0;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 19px;
			margin-bottom: 53px;
			color: #9D9D9C;
			text-align: left;
		}

		.row {
			display: grid;
			align-items: center;
			grid-template-columns: 3fr 4fr 2fr;
			gap: 26px;
			margin-bottom: 15px;

			span {
				display: none;
			}

			.al {
				background: #E30613;
				height: 34px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-style: normal;
				font-weight: 500;
				font-size: 22px;
				line-height: 21px;
				margin: 0;
				color: #FFFFFF;
			}

			ul {
				padding: 0;
				margin: 0;
				margin-top: 6px;
				list-style-type: none;
				text-align: left;
				width: 489px;
				margin: 0 auto;

				li {
					position: relative;
					padding-left: 20px;
					margin-bottom: 8px;

					p {
						margin: 0;
						font-weight: normal;
						font-size: 16px;
						line-height: 15px;
						color: #1C1339;
					}

					&:before {
						position: absolute;
						width: 8px;
						height: 8px;
						background: #1D71B8;
						content: '';
						left: 0;
						top: 4px;
					}
				}
			}
		}
	}

	img {
		width: 100%;
		margin: 60px 0;
	}
}

.img-dark {
	padding: 0 100px;
	background: #1E1839;

	img {
		width: 100%;
	}
}

.pandemics {
	h3 {
		margin: 0;
		font-style: normal;
		font-weight: bold;
		font-size: 28px;
		line-height: 31px;
		color: #FFFFFF;
		margin-bottom: 39px;
	}

	.group {
		color: white;
		display: flex;
		text-align: left;
		gap: 20px;
		justify-content: space-between;

		h5 {
			font-weight: normal;
			font-size: 20px;
			line-height: 19px;
			color: #FFFFFF;
			margin: 0;
			margin-bottom: 24px;
		}

		p {
			font-weight: normal;
			font-size: 14px;
			line-height: 150%;
			margin: 0;
			color: #FFFFFF;
		}

		.supply {
			max-width: 270px;

			p {
				margin-bottom: 28px;
			}
		}

		.ramo-up {
			max-width: 390px;

			.line {
				display: grid;
				grid-template-columns: 2fr 4fr;
				border-bottom: 1px solid;
				gap: 10px;
				padding: 14px 0px;
				align-items: center;

				h2 {
					margin: 0;
					font-size: 28px;
					line-height: 34px;
					color: #E30613;
				}

			}
		}

		.optinons {
			max-width: 290px;

			ul {
				padding: 0;
				margin-bottom: 28px;
				list-style-type: none;

				li {
					position: relative;
					padding-left: 20px;
					margin-bottom: 15px;

					&:before {
						position: absolute;
						width: 8px;
						height: 8px;
						background: #E30613;
						content: '';
						left: 0;
						top: 7px;
					}
				}
			}
		}
	}
}

.references {
	padding: 0 100px;
	background: #CCD5E3;
	padding-top: 58px;
	padding-bottom: 34px;

	h3 {
		font-style: normal;
		font-weight: 300;
		text-align: left;
		color: #0c0824;
		margin: 0;
		font-size: 32px;
		line-height: 38px;
		margin-bottom: 17px;
	}

	p {
		font-style: normal;
		font-weight: 300;
		line-height: 19px;
		color: #0C0824;
		text-align: left;
		font-size: 12px;
		line-height: 21px;
		font-weight: 300;
	}
}

@media screen and (max-width: 1280px) {
	.imgs.arps .row {
		grid-template-columns: 4fr 2fr 3fr;
	}
	.imgs.arps .row .al {
		font-size: 19px;
	}
}

@media screen and (max-width: 1100px) {
	.img-dark {
		padding: 60px 40px !important;
	}
	.pandemics {
		h3 {
			text-align: left;
			font-weight: bold;
			font-size: 22px;
			line-height: 21px;
			margin-bottom: 30px;
		}
	}
	.pandemics .group {
		display: grid;
		row-gap: 30px;
		flex-direction: column;

		h5 {
			font-weight: normal;
			font-size: 20px;
			line-height: 19px;
			margin-bottom: 20px;
		}

		.supply, .ramo-up, .optinons {
			max-width: unset;
		}

		.supply {
			p {
				margin-bottom: 12px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.imgs.arps {
		margin: 60px 40px;

		h5 {
			margin-bottom: 20px;
			font-size: 22px;
			line-height: 21px;
		}

		.row {
			grid-template-columns: 1fr;

			img {
				display: none;
			}

			.al {
				font-weight: normal;
				font-size: 18px;
				line-height: 17px;
				height: 29px;
			}

			ul {
				width: 100%;
			}

			span {
				background: #4569AE;
				font-size: 18px;
				line-height: 17px;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 29px;
			}
		}
	}
}

@media screen and (max-width: 900px) {
	.references {
		padding: 34px 40px;
		padding-bottom: 50px;
		padding-top: 50px;
	}
}

@media screen and (max-width: 560px) {
	.img-dark {
		padding: 25px 24px !important;
	}
	.pandemics .group .ramo-up .line h2 {
		font-size: 20px;
		line-height: 19px;
	}
	.references {
		padding: 30px 24px;
		padding-bottom: 50px;
		padding-top: 50px;
	}
	.imgs.arps {
		margin: 22px 24px;

		.row {
			grid-template-columns: 1fr;
			gap: 10px;

			img {
				display: none;
			}

			.al {
				font-weight: normal;
				font-size: 17px;
				line-height: 17px;
				height: 22px;
			}

			ul {
				width: 100%;

				li {
					&:last-child {
						margin: 0;
					}
				}
			}

			span {
				background: #4569AE;
				font-size: 17px;
				line-height: 17px;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 22px;
			}
		}
	}
}

</style>
