import {createRouter, createWebHashHistory} from "vue-router";
import Home from "../pages/Home.vue";
import Focus from "../pages/Focus.vue";
import Team from "../pages/Team.vue";
import BurdenOfArps from "../pages/BurdenOfArps.vue";
import SubstantialGaps from "../pages/SubstantialGaps.vue";
import Phoxgen from "../pages/Phoxgen.vue";
import Phoxwell from "../pages/Phoxwell.vue";
import ClinicalTrial from "../pages/ClinicalTrial.vue";
import News from "../pages/News.vue";
import Contact from "../pages/Contact.vue";
import MediaKit from "../pages/MediaKit.vue";
import SolutionBreakthrough from "../pages/SolutionBreakthrough.vue";

import CEO_Martin_Walton_Press_Release from '../pages/news_articles/CEO-Martin-Walton-Press-Release.vue';
import pHOXBIO_CM_Press_Release_web_22_9_21 from '../pages/news_articles/pHOXBIO_CM_Press_Release_web_22_9_21.vue';
import pHOXWELL_Paper_Press_Release_July_26th from '../pages/news_articles/pHOXWELL_Paper_Press_Release_July_26th.vue';
import Raphael_Labs_CM_Press_Release_2_11_21 from '../pages/news_articles/Raphael_Labs_CM_Press_Release_2_11_21.vue';

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            description:
                "pHOXBIO is a privately held biopharmaceutical company developing pHOXGEN and pHOXWELL, two unique solutions targeting prophylaxis of airborne respiratory viruses (ARVs) such as coronaviruses.",
        },
    },
    {
        path: "/our-focus",
        name: "Our Focus",
        component: Focus,
        meta: {
            description:
                "pHOXBIO is committed to developing medicines and wellness products using scientific breakthroughs to protect society from the spread of airborne respiratory viruses that",
        },
    },
    {
        path: "/team",
        name: "Team",
        component: Team,
        meta: {
            description:
                "pHOXBIO is led by a strong management team with a diverse background and deep experience in the biopharmaceutical industry, academia and commerce.",
        },
    },
    {
        path: "/burden-of-arps",
        name: "Burden of Arps",
        component: BurdenOfArps,
        meta: {
            description:
                "Preventable respiratory diseases are a major source of morbidity and mortality in the developing world, consistently ranking among a top-five cause of death. Amongst the most important of airborne respiratory pathogens are viruses",
        },
    },
    {
        path: "/substantial-gaps",
        name: "Substantial Gaps",
        component: SubstantialGaps,
        meta: {
            description:
                "Substantial gaps in current standards of care to control ARVs. PPE to prevent transmission. Prophylactic medicines. Good hygiene",
        },
    },
    {
        path: "/solution-breakthrough",
        name: "Solution Breakthrough",
        component: SolutionBreakthrough,
        meta: {
            description:
                "Effective solutions – breakthrough science with potential for immense benefits to humanity. pHOXBIO is developing two self-administered prophylactic nasal sprays that inhibit the infection of Airborne Respiratory Viruses (ARVs) from initiating the infection.",
        },
    },
    {
        path: "/phoxgen",
        name: "pHOXGEN",
        component: Phoxgen,
        meta: {
            description:
                "Pharmacologic barrier mainly comprised of components from the U.S. Food and Drug Administration’s Generally Regarded as Safe (GRAS) list, that mimics the natural interstitial fluid surrounding all cells and maintains healthy cell function.",
        },
    },
    {
        path: "/phoxwell",
        name: "pHOXWELL",
        component: Phoxwell,
        meta: {
            description:
                "pHOXWELL is comprised of natural virucides which act in concert with our platform to inhibit viral infection. There is further potential for its use as a post-exposure prophylaxis.",
        },
    },
    {
        path: "/clinicaltrial",
        name: "Clinical Trial",
        component: ClinicalTrial,
        meta: {
            description: "ClinicalTrial.",
        },
    },
    {
        path: "/news",
        name: "news",
        component: News,
        meta: {
            description:
                "pHoxwell Tested Successfully on Rats and Rabbits. pHoxgen Phase II Trials to begin. pHoxwell approved by AYUSH. pHoxbio in Talks...",
        }
    },
    {
        path: "/contact",
        name: "contact",
        component: Contact,
        meta: {
            description:
                "pHOXBIO is rapidly advancing the development of its breakthrough science to stop the spread of airborne respiratory viruses.",
        },
    },
    {
        path: "/mediakit",
        name: "Media Kit",
        component: MediaKit,
        meta: {
            description: "pHOXBIO Media Kit",
        },
    },
    {
        path: "/news-ceo-martin-walton-press-release",
        name: "ceo-martin-walton-press-release",
        component: CEO_Martin_Walton_Press_Release,
        meta: {
            description: "CEO Martin Walton Press Release",
        },
    },
    {
        path: "/news-phoxbio-cm-press-release-web-22-9-21",
        name: "phoxbio-cm-press-release-web-22-9-21",
        component: pHOXBIO_CM_Press_Release_web_22_9_21,
        meta: {
            description: "pHOXBIO CM Press Release web 22 9 21",
        },
    },
    {
        path: "/news-phoxwell-paper-press-release-july-26th",
        name: "phoxwell-paper-press-release-july-26th",
        component: pHOXWELL_Paper_Press_Release_July_26th,
        meta: {
            description: "pHOXWELL Paper Press Release July 26th",
        },
    },
    {
        path: "/news-raphael-labs-cm-press-release-2-11-21",
        name: "raphael-labs-cm-press-release-2-11-21",
        component: Raphael_Labs_CM_Press_Release_2_11_21,
        meta: {
            description: "Raphael Labs CM Press Release 2 11 21",
        },
    }

];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0};
    },
});

// Inject meta description after loading route
router.afterEach((to) => {
    document.querySelector('meta[name="description"]').content =
        to.meta.description;

    // console.log('Next:', next);
    // next();
});

export default router;