<template>
	<div class="page">
		<div class="news-page">

			<div class="news" style="max-width: 800px;">

				<h2 style="margin-bottom: 50px;">For immediate release</h2>

				<p><b>The Journal of Clinical Virology (JCV) has accepted for publication Raphael Labs’
					paper on its ground-breaking SARS-CoV-2 intranasal prophylactic spray. </b></p>

				<p><b>The paper, entitled “Evaluating the efficacy and safety of a novel prophylactic
					nasal spray in the prevention of SARS-CoV-2 infection: A multi-centre, double blind,
					placebo-controlled, randomised trial” has been peer-reviewed. </b></p>

				<p><b>The paper focuses on pHOXWELL’s clinical trial involving at-risk, unvaccinated
					healthcare workers in India which showed the spray reduced SARS-CoV-2
					infections by 62% compared to placebo. </b></p>

				<p><b>Raphael Labs’ world class scientific team collaborated with leading scientists in the
					UK, India and the USA.</b></p>

				<p><b>London and Galway July 26th 2022.</b></p>

				<P>The peer-reviewed paper has been published online
					(<a href="https://www.sciencedirect.com/science/article/pii/S1386653222001809" target="_blank">https://www.sciencedirect.com/science/article/pii/S1386653222001809</a>) by the
					internationally reputed Journal of Clinical Virology, detailing pHOXWELL’s clinical
					trial in India which proved the spray reduced infection of SARS-CoV-2 by 62%
					compared to placebo (p&lt;=0.0001).</P>

				<p>The study reports the development of the novel treatment, which was found to be
					safe, well tolerated and simple to produce.</p>

				<p>Report author and Chairman of Raphael Labs (RL), Professor Rakesh Uppal “This
					peer-reviewed publication confirms that our ground-breaking nasal formulation
					provides a robust defence against infection from SARS-CoV-2. We have shown that
					the formulation is mutation-agnostic in vitro and is clinically effective against the
					virus. Infection of SARS-CoV-2 is still causing a huge burden on global health and a
					significant strain on the world economy. There is an increasing understanding that
					we need to look at agents that provide prophylaxis against this virus.”</p>

				<p>pHOXWELL is a combination of natural virucides and its breakthrough scientific
					platform that work in harness to prevent viral entry.</p>

				<p>Report author and RL Scientific Committee member Professor Angela Russell
					“Waves of SARS-CoV-2 continue to frustrate global efforts to control the spread of
					the virus. Prophylaxis is key to reducing these waves. There is mounting awareness in
					healthcare systems that prophylaxis is an important part of pandemic response. This
					prophylactic spray has two actions, it stops the virus from entering the body via the
					nasal epithelia cells and protects the cell from viral ingress. pHOXWELL is the first
					prophylactic spray that we know of which is clinically proven to prevent infection.”</p>

				<p>The multi-centre, double blind, randomized, placebo-controlled trial was
					conducted during the peak surge of the highly infectious Delta variant in India in
					April to July 2021. Key eligibility criteria included no previous SARS-CoV-2 infection or
					vaccination and absence of significant co-morbidity.</p>

				<p>The primary end point was the percentage of subjects who test positive for SARS-CoV-2 on IgGS (spike protein specific) over the 45 days of the study. Secondary
					end points included safety and tolerability measures.</p>

				<p>556 participants were analysed for the primary endpoint (275 Test; 281 Placebo) of
					which 13.1% of subjects were IgGS positive in the pHOXWELL arm versus 34.5% in the
					placebo arm (p= &lt;0.0001). This result shows that pHOXWELL has a significant
					prophylactic effect versus SARS-CoV-2 infection when compared to placebo.</p>

				<p>Fewer clinical symptoms were seen in the test group [57 cases (17.6%) vs 112 cases
					(34.6%); p&lt;0.0001]. pHOXWELL exhibited an excellent safety profile. No serious
					adverse events were reported in either arm of the study.</p>

				<p>For more information about pHOXWELL’s pivotal trial please visit:
					<a href="https://phoxbio.com/#/clinicaltrial">https://phoxbio.com/#/clinicaltrial</a></p>

				<p><b>Raphael Lab’s Scientific Team</b></p>

				<p>Raphael Labs’ (RL) scientific leadership team includes Professor Dame Kay Davies,
					Dr Lee’s Professor of Anatomy Emeritus at Oxford University; Professor Steve Davies,
					Waynflete Professor Emeritus of Chemistry at Oxford University; Alan Dunton M.D.,
					based in Boston; Mr. Goutham K Gorti, FRCS, based in New Jersey; Professor Áine
					McKnight, Professor of Viral Pathology at The Blizard Institute, Queen Mary University
					of London; Professor Angela Russell, Professor of Medicinal Chemistry at Oxford
					University; Dr Laurence Silpa, RL’s Scientific Co-ordinator, Dr Jim Swales,
					pHOXWELL’s clinical trial lead; Professor Mauro Teixeira, Professor of Immunology,
					Universidade Federal de Minas Gerais, and Professor Rakesh Uppal, Professor of
					Cardiovascular Surgery, William Harvey Research Institute, QMUL, and Director of
					Barts Life Sciences. Dr Manu Jaggi and Dr Anu Singh, Dabur Research Foundation.</p>

				<p>About Raphael Labs (RL)</p>

				<p>RL is a European biotech asset developer with a significant UK operation. RL deploys advanced science to prevent respiratory infections.</p>

				<p><b>Ends</b></p>

				Media Contact: Nicola Burdett, <a href="nb@raphlabs.com">nb@raphlabs.com</a>, <a href="media@raphlabs.com">media@raphlabs.com</a>
				<br>

				<a href="https://raphaellabs.com">https://raphaellabs.com</a> | <a href="https://phoxbio.com">https://phoxbio.com</a>

			</div>

		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.footer-nav {
	position: absolute;
	width: 100%;
	bottom: 0;
}

.news-page {
	padding: 0 100px;
	text-align: left;
}

.news-item {
	display: block;
	width: 100%;
	margin-top: 25px;
}

h3 {
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 31px;
	color: #9fa09f;
	margin-top: 63px;
	margin-bottom: 36px;
}

.news {
	margin-bottom: 44px;

	span {
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		display: block;
		color: #9fa09f;
	}

	p {
		font-style: normal;
		font-weight: 300;
		font-size: 17px;
		line-height: 20px;
		color: #0c0824;
		margin-bottom: 8px;
	}

	a {
		font-weight: 300;
		font-size: 17px;
		line-height: 20px;
		text-decoration-line: underline;
		color: #0c0824;
	}
}

@media screen and (max-width: 1100px) {
	.news-page {
		padding: 0 40px;
	}
}

@media screen and (max-width: 560px) {
	.news-page {
		padding: 0 24px;
	}
	h3 {
		font-size: 28px;
		margin-top: 47px;
		line-height: 34px;
	}
}
</style>
