<template>
    <div class="footer-nav">
        <nav aria-label="Main navigation" class="container-xxl flex-wrap flex-md-nowrap">
            <a aria-label="pHoxbio" class="navbar-brand p-0 me-2" href="/">
                <img alt="pHOXBIO" src="../assets/img/logo.svg"> </a>

            <ul class="navbar-nav flex-row flex-wrap ms-md-auto">
                <li>
                    <p class="nav-link">About</p>
                    <div class="sub-a-content">
                        <div class="outer">
                            <div class="inner"></div>
                        </div>
                        <router-link class="nav-link" to="our-focus">Our focus</router-link>
                        <router-link class="nav-link" to="team">Team</router-link>
                    </div>
                </li>
                <li>
                    <p class="nav-link">Disease</p>
                    <div class="sub-a-content" style="width: 120px;">
                        <div class="outer">
                            <div class="inner"></div>
                        </div>
                        <router-link class="nav-link" to="burden-of-arps">Burden of Arps</router-link>
                        <router-link class="nav-link" to="substantial-gaps">Substantial Gaps</router-link>
                    </div>
                </li>
                <li>
                    <p class="nav-link">Our Solutions</p>
                    <div class="sub-a-content">
                        <div class="outer">
                            <div class="inner"></div>
                        </div>
                        <router-link class="nav-link" to="solution-breakthrough">Solution Breakthrough</router-link>
                        <router-link class="nav-link" to="phoxgen">pHOXGEN</router-link>
                        <router-link class="nav-link" to="phoxwell">pHOXWELL</router-link>
                    </div>
                </li>
                <!-- <li>
                  <router-link class="nav-link" to="news">News</router-link>
                </li> -->
                <li>
                    <router-link class="nav-link" to="contact">Contact</router-link>
					<div class="sub-a-content">
						<div class="outer">
							<div class="inner"></div>
						</div>
						<router-link class="nav-link" to="mediakit">Media Kit</router-link>
					</div>
                </li>
            </ul>
        </nav>
        <p class="sub-logo">© Raphael Limited {{ year }}</p>
        <p class="sub-logo s">

            <a class="iubenda-nostyle no-brand iubenda-embed" href="https://www.iubenda.com/privacy-policy/56605070" title="Privacy Policy ">Privacy
                Policy</a> <span class="separator">|</span>
            <a class="iubenda-nostyle no-brand iubenda-embed" href="https://www.iubenda.com/privacy-policy/56605070/cookie-policy" title="Cookie Policy ">Cookie
                Policy</a> <span class="separator">|</span>
            <a class="iubenda-nostyle no-brand iubenda-embed" href="https://www.iubenda.com/terms-and-conditions/56605070" title="Terms and Conditions ">Terms
                and Conditions</a>
        </p>
    </div>
</template>

<script>
(function (w, d) {
    var loader = function () {
        var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
        s.src = "https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s, tag);
    };
    if (w.addEventListener) {
        w.addEventListener("load", loader, false);
    } else if (w.attachEvent) {
        w.attachEvent("onload", loader);
    } else {
        w.onload = loader;
    }
})(window, document);
export default {

    data() {
        return {
            year: null
        }
    },

    mounted() {
        const date = new Date();
        this.year = date.getFullYear();
    }

}
</script>

<style lang="scss" scoped>
nav {
    align-items: center;
    display: flex;
    height: 51px;
    justify-content: space-between;
    padding: 0 100px;
}

.navbar-nav {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        margin-left: 51px;
        position: relative;

        .nav-link {
            align-items: center;
            align-items: center;
            color: #0C0824;
            color: #FFFFFF;
            display: flex;
            display: flex;
            font-size: 18px;
            font-style: normal;
            font-weight: bold;
            gap: 11px;
            text-decoration: none;
            text-transform: uppercase;
        }
    }

    .sub-a-content {
        left: 0;
        position: absolute;
        top: 43px;

        .nav-link {
            color: #FFFFFF;
            font-size: 10px;
            font-weight: normal;
            line-height: 10px;
            margin-bottom: 10px;
            text-align: left;
            /* Neutrals/N0 */

            text-transform: uppercase;
        }
    }
}

.footer-nav {
    background: #0C0824;
    padding-bottom: 30px;
    padding-top: 40px;

    .navbar-brand {
        line-height: 0;

        img {
            width: 165px;
        }
    }

    .sub-logo {
        color: white;
        font-size: 10px;
        line-height: 10px;
        margin-top: 36px;
        padding-left: 100px;
        text-align: left;
        text-transform: uppercase;

        &.s {
            margin-top: 9px;

            a {
                color: #FFFFFF;
                font-size: 10px;
                line-height: 10px;
                text-decoration: none;
                /*margin-right: px;*/
                text-transform: uppercase;

                &:hover {
                    text-decoration: underline;
                }
            }


            .separator {
                margin: 0 7px 0 7px;
            }

        }
    }
}

@media screen and (max-width: 1100px) {
    nav {
        padding: 0 40px;
    }

    .footer-nav .sub-logo {
        padding-left: 40px;
    }

    nav.main-nav {
        .navbar-nav {
            display: none;
        }

        .menu-toggle {
            display: block;
        }
    }
}

@media screen and (max-width: 980px) {
    nav {
        align-items: flex-start;
        flex-direction: column;
        height: auto;
        padding: 0 24px;
    }

    .navbar-nav {
        align-items: flex-start;
        display: block;
        flex-direction: column;
        height: auto;
        height: auto;
        margin-bottom: 30px;
        margin-top: 40px;
        width: 100%;
    }

    .footer-nav .sub-logo {
        padding-left: 24px;
    }

    nav.main-nav {
        .navbar-nav {
            display: none;
        }

        .menu-toggle {
            display: block;
        }
    }
    .navbar-nav {
        display: block;
        margin-bottom: 30px;
        margin-top: 40px;
        width: 100%;

        .sub-a-content {
            position: static;
            width: 100%;

            .nav-link {
                font-size: 16px;
                font-weight: 300;
                line-height: 19px;
            }
        }

        li {
            display: block;
            margin-bottom: 17px;
            margin-left: 0;
        }

        .nav-link {
            display: block;
            text-align: left;
            width: 100%;
        }
    }
}

@media screen and (max-width: 560px) {
    .navbar-nav {
        display: block;
        margin-bottom: 30px;
        margin-top: 40px;
        width: 100%;

        .sub-a-content {
            position: static;
            width: 100% !important;

            .nav-link {
                font-size: 16px;
                font-weight: 300;
                line-height: 19px;
            }
        }

        li {
            display: block;
            margin-bottom: 17px;
            margin-left: 0;
        }

        .nav-link {
            display: block;
            text-align: left;
            width: 100%;
        }
    }
    .footer-nav .sub-logo.s a {
        line-height: 19px;
    }
}
</style>
